import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SummaryService {
  private getSummaryKey = 'getsummary';

  constructor(private apiService: ApiService) {}

  getSummaryData(data) {
    return this.apiService.post(this.getSummaryKey, data);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductApplicationService {
  private getwalletdepositaddressesKey = 'getwalletdepositaddresses';
  private getcollateralloandataKey = 'getcollateralloandata';

  constructor(private apiService: ApiService) {}

  getWalletDepositAddresses(data) {
    return this.apiService.post(this.getwalletdepositaddressesKey, data);
  }

  getCollateralLoanData(data) {
    return this.apiService.post(this.getcollateralloandataKey, data);
  }
}

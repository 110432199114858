import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';

@Injectable()
export class SideBarService {
  private sideBarKey = 'sidebar';
  private updatenotificationreadKey = 'updatenotificationread';

  private sideBarExpanded = new BehaviorSubject<boolean>(true);
  isSideBarExpanded = this.sideBarExpanded.asObservable();

  private menuPermissions = new BehaviorSubject<any[]>([]);
  menuPermission = this.menuPermissions.asObservable();

  private profile = new BehaviorSubject<any>({});
  profileDetails = this.profile.asObservable();

  constructor(private apiService: ApiService) {}

  updateProfileName(val) {
    this.profile.next(val);
  }

  getSideBar(data) {
    return this.apiService.post(this.sideBarKey, data);
  }

  updateSideBarExpanded(val) {
    this.sideBarExpanded.next(val);
  }

  updateMenuPermissions(val) {
    this.menuPermissions.next(val);
  }

  updateNotificatonRead(data) {
    return this.apiService.post(this.updatenotificationreadKey, data);
  }
}

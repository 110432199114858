import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private assetpriceKey = 'getassetprice';
  private getbankingdepositdetailsKey = 'getbankingdepositdetails';

  constructor(private apiService: ApiService) {}

  getAssetPrice(data) {
    return this.apiService.post(this.assetpriceKey, data);
  }

  getBlueAssetBankingDetails(data) {
    return this.apiService.post(this.getbankingdepositdetailsKey, data);
  }
}

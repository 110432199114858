<div class="banner-content">
  <div class="content">
    <div class="pd-20">
      <h2 class="gold-h2 align-center">Save It Before You Need It</h2>
    </div>
    <div class="pd-20-2 align-center">
      BlueAsset Protect is the Foundation of BlueAsset
    </div>
    <div class="three-cards mt-4">
      <div class="card-item card-item-middle">
        <div class="gold-h2 align-center">BlueAsset Water Rescue</div>
        <div>Save our water before it's too late.</div>
      </div>
      <div class="card-item">
        <div class="gold-h2 align-center">BlueAsset Education Rewards</div>
        <div>BlueAsset unique Reward and Education System.</div>
      </div>
    </div>
  </div>
</div>
<div class="banner-content">
  <div class="content">
    <div class="bottom-content">
      <div>
        <div class="bottom-header">BlueAsset Water Rescue</div>
        <div class="hr-holder"></div>
        <div class="mt-4">
          We have to Protect what we have, and improve upon it.
        </div>
        <div class="mt-4">
          At BlueAsset Protect we do not just aim to restore our natural water
          supply to its former beauty and resourcefulness, but we are building
          solid infrastructures and implementing protocols that will bring life
          back to our rivers and keep them clean.
        </div>
        <div class="mt-4">
          Each project will have a safeguard plan on sustainability of the river
          and its surrounding environment.
        </div>
        <div class="bottom-header mt-5">BlueAsset Water Rescue</div>
        <div class="hr-holder"></div>
        <div class="mt-4">
          In order to get communities to assist in cleaning and preserving our
          water supplies, we implement a unique reward system. This system
          educates them in understanding how important it is to preserve our
          water supply.
        </div>
        <div class="mt-4">
          This unique reward education system assists communities to feed their
          families and teaches them that we are all capable of more than our
          past, building a better future together.
        </div>
        <div class="mt-4">"Rewards that help to Educate a Nation"</div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AccumulationService {
  private accumulationsummaryKey = 'getaccumulationsummary';

  constructor(private apiService: ApiService) {}

  getAccumulationSummary(data) {
    return this.apiService.post(this.accumulationsummaryKey, data);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class IncomeService {
  private incomesummaryKey = 'getincomesummary';
  private updateincomepayoutKey = 'updateincomepayout';

  constructor(private apiService: ApiService) {}

  getIncomeSummary(data) {
    return this.apiService.post(this.incomesummaryKey, data);
  }

  updateIncomePayout(data) {
    return this.apiService.post(this.updateincomepayoutKey, data);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CommissionsService {
  private getcommissionsummaryKey = 'getcommissionsummary';
  private getcommissionforagentKey = 'getcommissionforagent';
  private getcommissionforintroducerKey = 'getcommissionforintroducer';
  private getcommissionformyselfKey = 'getcommissionformyself';

  constructor(private apiService: ApiService) {}

  getCommissionsSummary(data) {
    return this.apiService.post(this.getcommissionsummaryKey, data);
  }

  getCommissionsForAgent(data) {
    return this.apiService.post(this.getcommissionforagentKey, data);
  }

  getCommissionsForIntroducer(data) {
    return this.apiService.post(this.getcommissionforintroducerKey, data);
  }

  getCommissionsForMyself(data) {
    return this.apiService.post(this.getcommissionformyselfKey, data);
  }
}

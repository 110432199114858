import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public year: number;

  public isLoading: boolean;

  constructor() {
    this.year = 2020;
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.isLoading = true;
    let date = new Date();
    this.year = date.getFullYear();
    this.isLoading = false;
  }

  goToInstagram() {
    window.open('https://www.instagram.com/blueassetgroup/', '_blank');
  }

  goToFacebook() {
    window.open(
      'https://www.facebook.com/pages/category/Currency-Exchange/BlueAsset-Group-100346091842830',
      '_blank'
    );
  }

  goToLinkedin() {
    window.open(
      'https://www.linkedin.com/company/blueasset-group/about',
      '_blank'
    );
  }

  goToWhatsapp() {
    window.open('https://wa.me/27742141170', '_blank');
  }

  goToTwitter() {
    window.open('https://twitter.com/BlueAssetG', '_blank');
  }
}

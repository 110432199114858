import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { SideBarService } from './side-bar.service';

@Injectable()
export class LoginService {
  private loginKey = 'login';
  private loginOtp = 'loginotp';
  private resetpasswordKey = 'resetpassword';
  private register = 'register';
  private verifyemail = 'verifyemail';
  private recoverycodeKey = 'userecoverycode';
  private isLoggedIn = environment.isLoggedIn;

  private loggedIn = new BehaviorSubject<boolean>(this.isLoggedIn);
  login = this.loggedIn.asObservable();

  constructor(private router: Router, private apiService: ApiService) {}

  // Logout user
  public logout() {
    this.authLogout();
    this.router.navigate(['/home']);
  }

  // Auth logout
  private authLogout() {
    sessionStorage?.removeItem('x-token');
    this.updateLogin(false);
  }

  // Authenticate a user via auth
  authenticate(data) {
    return this.apiService.post(this.loginKey, data);
  }

  // Authenticate a user via OTP
  authenticateOTP(data) {
    return this.apiService.post(this.loginOtp, data);
  }

  // reset password via email.
  resetPassword(data) {
    return this.apiService.post(this.resetpasswordKey, data);
  }

  // When the user lost his phone etc
  useRecoveryCode(data) {
    return this.apiService.post(this.recoverycodeKey, data);
  }

  updateLogin(val) {
    this.loggedIn.next(val);
  }

  // new sign up from site
  registerUser(data) {
    return this.apiService.post(this.register, data);
  }

  // verify email after submis
  submitVerifyEmail(data) {
    return this.apiService.post(this.verifyemail, data);
  }
}

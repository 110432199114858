import { Injectable } from '@angular/core';
import { LoaderService } from './loader.service';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class StatementService {
  public logo: any;

  constructor(
    private loaderService: LoaderService,
    private datePipe: DatePipe,
    private utilService: UtilService
  ) {
    this.logo = this.utilService.logo;
  }

  async generateSummaryStatement(user: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);
      let pdfName =
        'Summary_Statement_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let totalsArray = [];
      let extraData = user.extraData;

      let totalInvestedFlexi = 0;
      let totalInvestedSmart = 0;
      let totalInvestedRetire = 0;

      for (let index = 0; index < extraData.flexiProducts.length; index++) {
        let flexiItem = extraData.flexiProducts[index];
        totalInvestedFlexi += flexiItem.currentInvestment;
      }

      for (let index = 0; index < extraData.smartProducts.length; index++) {
        let smartItem = extraData.smartProducts[index];
        totalInvestedSmart += smartItem.currentInvestment;
      }

      for (let index = 0; index < extraData.retireProducts.length; index++) {
        let retireItem = extraData.retireProducts[index];
        totalInvestedRetire += retireItem.currentInvestment;
      }

      let totalIncomeForMonthInvested = [
        [
          { text: 'Total Invested Flexi Income Plan' },
          {
            text: totalInvestedFlexi?.toFixed(2) + ' USDT',
            alignment: 'right',
            bold: true,
          },
        ],
        [
          { text: 'Total Invested Flexi Smart Plan' },
          {
            text: totalInvestedSmart?.toFixed(2) + ' USDT',
            alignment: 'right',
            bold: true,
          },
        ],
        [
          { text: 'Total Invested Retire Plan' },
          {
            text: totalInvestedRetire?.toFixed(2) + ' USDT',
            alignment: 'right',
            bold: true,
          },
        ],
      ];

      let totalInvestedAllIncome =
        totalInvestedFlexi + totalInvestedSmart + totalInvestedRetire;

      // Income Summary
      let incomeData = [
        { text: ' ' },
        {
          text: 'Income Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Income Payout For Month' },
                {
                  text: extraData.totalIncomeForMonth?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Income Date' },
                {
                  text: '25th of each month',
                  alignment: 'right',
                },
              ],
              ...totalIncomeForMonthInvested,
            ],
          },
        },
      ];

      let allAssetsValue = 0;

      allAssetsValue +=
        extraData.totalAssets.bitcoin * extraData.marketPrice.bitcoin;
      allAssetsValue +=
        extraData.totalAssets.ethereum * extraData.marketPrice.ethereum;
      allAssetsValue += extraData.totalAssets.basc * extraData.marketPrice.usdc;
      allAssetsValue += extraData.totalAssets.bacc * extraData.marketPrice.bacc;

      // Asset Summary
      let assetData = [
        { text: ' ' },
        {
          text: 'BA Funds Summary',
          style: 'sectionHeader',
          alignment: 'left',
          pageBreak: 'before',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Value of all BA Funds at market price', bold: true },
                {
                  text: allAssetsValue.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BA Funds Purchased (BTC)', bold: true },
                {
                  text: extraData.totalAssets.bitcoin.toFixed(8) + ' BTC',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Value of BA Funds at market price' },
                {
                  text:
                    (
                      extraData.totalAssets.bitcoin *
                      extraData.marketPrice.bitcoin
                    ).toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BA Funds Purchased (ETH)', bold: true },
                {
                  text: extraData.totalAssets.ethereum.toFixed(8) + ' ETH',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Value of BA Funds at market price' },
                {
                  text:
                    (
                      extraData.totalAssets.ethereum *
                      extraData.marketPrice.ethereum
                    ).toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BA Funds Purchased (BASC)', bold: true },
                {
                  text: extraData.totalAssets.basc.toFixed(8) + ' BASC',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Value of BA Funds at market price' },
                {
                  text:
                    (
                      extraData.totalAssets.basc * extraData.marketPrice.usdc
                    ).toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BA Funds Purchased (BACC)', bold: true },
                {
                  text: extraData.totalAssets.bacc.toFixed(8) + ' BACC',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Value of BA Funds at market price' },
                {
                  text:
                    (
                      extraData.totalAssets.bacc * extraData.marketPrice.bacc
                    ).toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Leverage Summary
      let loanBreakdown = [
        { text: ' ' },
        {
          text: 'Leverage Breakdown',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Loan Invested Amount (USDT)' },
                {
                  text: extraData.loanData.totalInvested.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Total Loan Outstanding Amount (USDT)' },
                {
                  text:
                    extraData.loanData.totalOutstanding.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Total Loan Liquidation Amount (USDT)' },
                {
                  text:
                    extraData.loanData.totalLiquidatedPayBack.toFixed(2) +
                    ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Total Loan Repayment Amount (USDT)' },
                {
                  text:
                    extraData.loanData.totalRepaidAmount.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
            ],
          },
        },
      ];

      let usdtWalletBalance =
        extraData.walletData.find((x) => x.type === 'usdt').balance || 0;
      let btcWalletBalance =
        extraData.walletData.find((x) => x.type === 'bitcoin').balance || 0;
      let ethWalletBalance =
        extraData.walletData.find((x) => x.type === 'ethereum').balance || 0;
      let bascWalletBalance =
        extraData.walletData.find((x) => x.type === 'basc').balance || 0;
      let tusdWalletBalance =
        extraData.walletData.find((x) => x.type === 'tusd').balance || 0;
      let baccWalletBalance =
        extraData.walletData.find((x) => x.type === 'bacc').balance || 0;

      // Wallet Summary
      let walletBreakdown = [
        { text: ' ' },
        {
          text: 'Wallet Balances',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: true,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Wallet Balance (USDT)' },
                {
                  text: usdtWalletBalance.toFixed(8) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Wallet Balance (BTC)' },
                {
                  text: btcWalletBalance.toFixed(8) + ' BTC',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Wallet Balance (ETH)' },
                {
                  text: ethWalletBalance.toFixed(8) + ' ETH',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Wallet Balance (BASC)' },
                {
                  text: bascWalletBalance.toFixed(8) + ' BASC',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Wallet Balance (TUSD)' },
                {
                  text: tusdWalletBalance.toFixed(8) + ' TUSD',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Wallet Balance (BACC)' },
                {
                  text: baccWalletBalance.toFixed(8) + ' BACC',
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let fullPayOutAmount = '';
      let totalDueToUser =
        allAssetsValue +
        totalInvestedAllIncome -
        extraData.loanData.totalOutstanding;

      if (totalDueToUser >= 0) {
        fullPayOutAmount = '$' + totalDueToUser.toFixed(2);
      } else {
        fullPayOutAmount = '-$' + totalDueToUser.toFixed(2);
      }

      // Income Summary
      let settlementData = [
        { text: ' ' },
        {
          text: 'Settlement Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          text: 'To close/cancel all your products the following needs to happen:',
        },
        {
          text: '',
        },
        {
          style: 'listItems',
          ul: [
            {
              text: [
                'You need to settle all your loans (you need to provide these funds) with a current outstanding balance of ',
                {
                  text: '$' + extraData.loanData.totalOutstanding.toFixed(2),
                  bold: true,
                },
              ],
            },
            {
              text: [
                'Once you have settled all your loans, we will sell your BA Funds worth ',
                {
                  text: '$' + allAssetsValue.toFixed(2),
                  bold: true,
                },
              ],
            },
            {
              text: [
                'We will close your income generating products worth ',
                {
                  text: '$' + totalInvestedAllIncome.toFixed(2),
                  bold: true,
                },
              ],
            },
            {
              text: [
                'We also owe you an amount for your liquidations worth ',
                {
                  text:
                    '$' + extraData.loanData.totalLiquidatedPayBack.toFixed(2),
                  bold: true,
                },
                ' (PLEASE NOTE - You will forfeit this amount if you close/cancel your products, and we no longer owe you this).',
              ],
            },
            {
              text: [
                'Thus your total payout will be ',
                {
                  text:
                    '$' +
                    allAssetsValue.toFixed(2) +
                    ' + $' +
                    totalInvestedAllIncome.toFixed(2) +
                    ' - $' +
                    extraData.loanData.totalOutstanding.toFixed(2) +
                    ' = ' +
                    fullPayOutAmount,
                  bold: true,
                },
              ],
            },
          ],
        },
      ];

      // Statement Header
      let statementHeader = 'Summary Statement: ';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: false,
            columns: [[...incomeData], []],
          },
          {
            dontBreakRows: false,
            columns: [[...assetData], []],
          },
          {
            dontBreakRows: false,
            columns: [[...loanBreakdown], []],
          },
          {
            dontBreakRows: true,
            columns: [[...walletBreakdown], []],
          },
          {
            dontBreakRows: true,
            columns: [[...settlementData]],
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
          listItems: {
            margin: [10, 10, 0, 10],
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === true) {
        pdfMake.createPdf(docDefinition).download(pdfName);
      } else {
        pdfMake.createPdf(docDefinition).open();
      }
      this.loaderService.updateLoader(false);

      // Generate the pdf
      return docDefinition;
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // Transaction Statement
  async generateTransactionStatement(
    transactions: any,
    transactionTypes: any,
    user: any,
    walletBalances: any,
    download: boolean
  ) {
    try {
      this.loaderService.updateLoader(true);
      // First lines are headers
      let firstLineItemArray =
        'Status,Date,Description,Reference,Amount,Type'.split(',');

      // Statement Header
      let statementHeader = 'Transaction Statement: ';
      let pdfName =
        'Transaction_Statement_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      // Totals
      let transactionsArray = [];
      let walletsArray = [];

      // Populate Wallet Balances
      for (let index = 0; index < walletBalances.length; index++) {
        const wallet = walletBalances[index];

        walletsArray.push([
          { text: wallet.type?.toUpperCase() + ' (Total)' },
          {
            text: wallet?.balance?.toFixed(8),
            alignment: 'right',
          },
        ]);
      }

      // Sort transaction latest first
      transactions.sort((a: any, b: any) => b.dateAdded - a.dateAdded);

      // Populate transactions
      for (let index = 0; index < transactions.length; index++) {
        const transaction = transactions[index];

        let contentItem = [
          {
            text: this.utilService.capitalizeFirstLetter(transaction.status),
          },
          {
            text: this.datePipe.transform(
              new Date(transaction.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          {
            text: transactionTypes[transaction.type],
          },
          {
            text: transaction._id.toString(),
          },
          { text: transaction.amount?.toFixed(8) },
          {
            text: transaction.coinType.toUpperCase(),
          },
        ];
        transactionsArray.push(contentItem);
      }

      let docDefinition = {
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',

        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: false,
            columns: [
              [
                {
                  text: 'Wallet Balances',
                  style: 'sectionHeader',
                  alignment: 'left',
                  pageBreak: 'before',
                },
                {
                  table: {
                    widths: [150, '*'],
                    dontBreakRows: false,
                    headerRows: walletsArray.length,
                    keepWithHeaderRows: walletsArray.length,
                    body: [
                      [
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                      ],
                      ...walletsArray,
                    ],
                  },
                },
              ],
              [],
            ],
          },
          {
            text: 'Transaction History',
            style: 'sectionHeader',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [34, 44, '*', '*', 120, 44],

              body: [firstLineItemArray, ...transactionsArray],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === true) {
        pdfMake.createPdf(docDefinition).download(pdfName);
      } else {
        pdfMake.createPdf(docDefinition).open();
      }
      this.loaderService.updateLoader(false);
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // Agent Comms Statement
  async generateIntroducerCommsStatement(agent: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);
      // Second lines are headers
      let secondLineItemArray =
        'Date,Client Name,Client Contact,Amount (Activate/Payout),Commission,Funds Source'.split(
          ','
        );

      let introducer = agent.introducers[0];
      // Statement Header
      let statementHeader = 'Introducer Commission Statement: ';
      let pdfName =
        'Introducer_Commission_Statement_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      // Totals
      let introducersBreakdown = [];
      let totalsArray = [];
      totalsArray.push([
        { text: 'Total Sales (USDT)' },
        {
          text: '$' + introducer.totals.totalSalesAmount.toFixed(8),
          alignment: 'right',
        },
      ]);
      totalsArray.push([
        { text: 'Total Commission On Sales (USDT)' },
        {
          text: '$' + introducer.totals.totalCommsAmount.toFixed(8),
          alignment: 'right',
        },
      ]);

      // Populate Introducers Breakdown
      for (let index = 0; index < agent.commissionItems.length; index++) {
        const commissionItem = agent.commissionItems[index];

        let contentItem = [
          {
            text: this.datePipe.transform(
              new Date(commissionItem.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          {
            text:
              this.utilService?.capitalizeFirstLetter(
                commissionItem?.user?.name || 'CLOSED'
              ) +
              ' ' +
              this.utilService?.capitalizeFirstLetter(
                commissionItem?.user?.surname || 'MOVED'
              ),
          },
          {
            text:
              (commissionItem?.user?.email || 'N/A') +
              ' (' +
              (commissionItem?.user?.cell || 'N/A') +
              ')',
          },
          {
            text: '$' + commissionItem.amount.toFixed(2),
          },
          {
            text: '$' + commissionItem.amountIntroducer.toFixed(2),
          },
          {
            text: commissionItem.type.toUpperCase(),
          },
        ];
        introducersBreakdown.push(contentItem);
      }

      let docDefinition = {
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',

        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Introducer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', introducer.name + ' ' + introducer.surname],
                      ['Address', introducer.address?.street],
                      ['', introducer.address?.suburb],
                      ['', introducer.address?.city],
                      ['', introducer.address?.country],
                      ['Email', introducer.email],
                      ['Contact', introducer.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: false,
            columns: [
              [
                {
                  text: 'Total Sales',
                  style: 'sectionHeader',
                  alignment: 'left',
                  pageBreak: 'before',
                },
                {
                  table: {
                    widths: [150, '*'],
                    dontBreakRows: false,
                    headerRows: totalsArray.length,
                    keepWithHeaderRows: totalsArray.length,
                    body: [
                      [
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                      ],
                      ...totalsArray,
                    ],
                  },
                },
              ],
              [],
            ],
          },
          {
            text: 'Sales Breakdown',
            style: 'sectionHeader',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [70, 120, 250, '*', '*', 70],

              body: [secondLineItemArray, ...introducersBreakdown],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === true) {
        pdfMake.createPdf(docDefinition).download(pdfName);
      } else {
        pdfMake.createPdf(docDefinition).open();
      }
      this.loaderService.updateLoader(false);
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // Agent Comms Statement
  async generateAgentCommsStatement(agent: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);
      // First lines are headers
      let firstLineItemArray =
        'Introducer Name, Introducer Contact,Percentage of Pool,Total Sales'.split(
          ','
        );

      // Second lines are headers
      let secondLineItemArray =
        'Date,Introducer Name,Client Name,Client Contact,Amount (Activate/Payout),Comission,Funds Source'.split(
          ','
        );

      // Statement Header
      let statementHeader = 'Agent Commission Statement: ';
      let pdfName =
        'Agent_Commission_Statement_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      // Totals
      let transactionsArray = [];
      let introducersBreakdown = [];
      let totalsArray = [];
      totalsArray.push([
        { text: 'Total Sales (USDT)' },
        {
          text: '$' + agent.totals.totalSalesAmount.toFixed(8),
          alignment: 'right',
        },
      ]);
      totalsArray.push([
        { text: 'Total Commission On Sales (USDT)' },
        {
          text: '$' + agent.totals.totalCommsAmount.toFixed(8),
          alignment: 'right',
        },
      ]);

      // Populate Introducers
      for (let index = 0; index < agent.introducers.length; index++) {
        const introducer = agent.introducers[index];

        let contentItem = [
          {
            text:
              this.utilService?.capitalizeFirstLetter(introducer.name) +
              ' ' +
              this.utilService?.capitalizeFirstLetter(introducer.surname),
          },
          {
            text: introducer.email + ' (' + introducer.cell + ')',
          },
          {
            text: introducer.totals.totalPercentageOfPool.toFixed(2) + '%',
          },
          {
            text: '$' + introducer.totals.totalSalesAmount.toFixed(2),
          },
        ];
        transactionsArray.push(contentItem);
      }

      // Populate Introducers Breakdown
      for (let index = 0; index < agent.commissionItems.length; index++) {
        const commissionItem = agent.commissionItems[index];

        let contentItem = [
          {
            text: this.datePipe.transform(
              new Date(commissionItem.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          {
            text:
              this.utilService?.capitalizeFirstLetter(
                commissionItem.introducer.name
              ) +
              ' ' +
              this.utilService?.capitalizeFirstLetter(
                commissionItem.introducer.surname
              ),
          },
          {
            text:
              this.utilService?.capitalizeFirstLetter(
                commissionItem?.user?.name || 'CLOSED'
              ) +
              ' ' +
              this.utilService?.capitalizeFirstLetter(
                commissionItem?.user?.surname || 'MOVED'
              ),
          },
          {
            text:
              (commissionItem?.user?.email || 'N/A') +
              ' (' +
              (commissionItem?.user?.cell || 'N/A') +
              ')',
          },
          {
            text: '$' + commissionItem.amount.toFixed(2),
          },
          {
            text: '$' + commissionItem.amountIntroducer.toFixed(2),
          },
          {
            text: commissionItem.type.toUpperCase(),
          },
        ];
        introducersBreakdown.push(contentItem);
      }

      let docDefinition = {
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',

        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Agency Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', agent.name + ' ' + agent.surname],
                      ['Address', agent.address?.street],
                      ['', agent.address?.suburb],
                      ['', agent.address?.city],
                      ['', agent.address?.country],
                      ['Email', agent.email],
                      ['Contact', agent.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: false,
            columns: [
              [
                {
                  text: 'Agency Sales',
                  style: 'sectionHeader',
                  alignment: 'left',
                  pageBreak: 'before',
                },
                {
                  table: {
                    widths: [150, '*'],
                    dontBreakRows: false,
                    headerRows: totalsArray.length,
                    keepWithHeaderRows: totalsArray.length,
                    body: [
                      [
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                      ],
                      ...totalsArray,
                    ],
                  },
                },
              ],
              [],
            ],
          },
          {
            text: 'Introducer Sales',
            style: 'sectionHeader',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ['*', '*', 40, 100],

              body: [firstLineItemArray, ...transactionsArray],
            },
          },
          {
            text: 'Introducer Sales Breakdown',
            style: 'sectionHeader',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [70, 120, 120, 120, '*', '*', 70],

              body: [secondLineItemArray, ...introducersBreakdown],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === true) {
        pdfMake.createPdf(docDefinition).download(pdfName);
      } else {
        pdfMake.createPdf(docDefinition).open();
      }
      this.loaderService.updateLoader(false);
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  async generateSalesAgentCsvStatement(salesData) {
    try {
      this.loaderService.updateLoader(true);
      let commissionItems = salesData.commissionItems;

      let csvContent =
        'data:text/csv;charset=utf-8,' +
        'Date,Client Name,Client Contact,Investment Amount ($),Commission ($),Funds Source,Introducer Name';

      for (let index = 0; index < commissionItems.length; index++) {
        let salesItem = commissionItems[index];

        csvContent +=
          '\n' +
          this.datePipe.transform(new Date(salesItem.dateAdded), 'yyyy-MM-dd') +
          ',' +
          (salesItem?.user?.name || 'CLOSED') +
          ' ' +
          (salesItem?.user?.surname || 'MOVED') +
          ',' +
          (salesItem?.user?.email || 'N/A') +
          ' (' +
          (salesItem?.user?.cell || 'N/A') +
          ')' +
          ',' +
          salesItem.amount.toFixed(8) +
          ',' +
          salesItem.amountIntroducer.toFixed(8) +
          ',' +
          salesItem.type.toUpperCase() +
          ',' +
          salesItem.introducer.name +
          ' ' +
          salesItem.introducer.surname;
      }

      let encodedData = encodeURI(csvContent);
      let fileName =
        'Agent_Commission_Statement_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.csv';

      let link = document.createElement('a');
      link.setAttribute('href', encodedData);
      link.setAttribute('download', fileName);
      document.body.appendChild(link); // Required for Firefox
      link.click();

      this.loaderService.updateLoader(false);
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  async generateSalesIntroducerCsvStatement(salesData) {
    try {
      this.loaderService.updateLoader(true);

      let commissionItems = salesData.commissionItems;

      let csvContent =
        'data:text/csv;charset=utf-8,' +
        'Date,Client Name,Client Contact,Investment Amount ($),Commission ($),Funds Source,Introducer Name';

      for (let index = 0; index < commissionItems.length; index++) {
        let salesItem = commissionItems[index];

        csvContent +=
          '\n' +
          this.datePipe.transform(new Date(salesItem.dateAdded), 'yyyy-MM-dd') +
          ',' +
          (salesItem?.user?.name || 'CLOSED') +
          ' ' +
          (salesItem?.user?.surname || 'MOVED') +
          ',' +
          (salesItem?.user?.email || 'N/A') +
          ' (' +
          (salesItem?.user?.cell || 'N/A') +
          ')' +
          ',' +
          salesItem.amount.toFixed(8) +
          ',' +
          salesItem.amountIntroducer.toFixed(8) +
          ',' +
          salesItem.type.toUpperCase() +
          ',' +
          salesItem.introducer.name +
          ' ' +
          salesItem.introducer.surname;
      }

      let encodedData = encodeURI(csvContent);
      let fileName =
        'Introducer_Commission_Statement_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.csv';

      let link = document.createElement('a');
      link.setAttribute('href', encodedData);
      link.setAttribute('download', fileName);
      document.body.appendChild(link); // Required for Firefox
      link.click();

      this.loaderService.updateLoader(false);
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  async generateLoanCsvStatement(loanData) {
    try {
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        'Loan No,Date,USDT Deposits,Coin,BA Funds Purchased' +
        ',LTV,Original BA Funds Purchase Price (USDT),Loan LTV Liquidation (USDT),Collateral Added (USDT),Collateral Added (Coin)' +
        ',Collateral Loan Amount (USDT),Outstanding Loan Balance (USDT),Repaid Loan Amount (USDT),Percentage Loan Repaid,Loan State';

      for (let index = 0; index < loanData.length; index++) {
        let loanItem = loanData[index];

        let coinType = '';
        let loanState = 'Active';

        switch (loanItem.coinType) {
          case 'bitcoin':
            coinType = 'BTC';
            break;
          case 'ethereum':
            coinType = 'ETH';
            break;
          case 'basc':
            coinType = 'BASC';
            break;
          default:
            break;
        }

        if (loanItem.settled === true) {
          loanState = 'Settled';
        }

        if (loanItem.liquidated === true) {
          loanState = 'Liquidated';
        }

        csvContent +=
          '\n' +
          loanItem._id.slice(loanItem._id.length - 8).toUpperCase() +
          ',' +
          this.datePipe.transform(new Date(loanItem.dateAdded), 'yyyy-MM-dd') +
          ',' +
          parseFloat(loanItem.initialInvestment).toFixed(2) +
          ',' +
          coinType +
          ',' +
          parseFloat(loanItem.totalCoins).toFixed(8) +
          ',' +
          parseInt(loanItem.loanPercentage) +
          '%' +
          ',' +
          parseFloat(loanItem.originalCoinValue).toFixed(2) +
          ',' +
          parseFloat(loanItem.ltvValue).toFixed(2) +
          ',' +
          parseFloat(loanItem.totalCollatoralAmount).toFixed(2) +
          ',' +
          parseFloat(loanItem.totalCollatoral).toFixed(8) +
          ',' +
          parseFloat(loanItem.loanAmount).toFixed(2) +
          ',' +
          parseFloat(loanItem.outstandingBalance).toFixed(2) +
          ',' +
          parseFloat(loanItem.repaidAmount).toFixed(2) +
          ',' +
          ((loanItem.repaidAmount / loanItem.loanAmount) * 100 || 0).toFixed(
            2
          ) +
          '%' +
          ',' +
          loanState;
      }

      let encodedData = encodeURI(csvContent);
      let fileName =
        'Digital_Asset_Loan_Statement' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.csv';

      let link = document.createElement('a');
      link.setAttribute('href', encodedData);
      link.setAttribute('download', fileName);
      document.body.appendChild(link); // Required for Firefox
      link.click();
      this.loaderService.updateLoader(false);
    } catch (err) {
      this.loaderService.updateLoader(false);
    }
  }

  // Income Statement
  async generateIncomeStatement(
    user: any,
    incomeProduct: any,
    download: boolean
  ) {
    try {
      this.loaderService.updateLoader(true);

      let totalsArray = [];

      // We add income to product section
      let productData = [
        { text: ' ' },
        {
          text: 'Fixed Income Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Initial Total Deposit' },
                {
                  text: incomeProduct?.initialInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Current Total Deposit' },
                {
                  text: incomeProduct?.currentInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Income Date' },
                {
                  text: '25th of each month',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Return Monthly Percentage' },
                {
                  text: '4%',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: incomeProduct?.payOutType.toUpperCase(),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Previous Month Payout (Includes Pro Rata)' },
                {
                  text: incomeProduct?.incomeForMonth?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Monthly Return (4%)' },
                {
                  text:
                    (incomeProduct?.currentInvestment * 0.04).toFixed(2) +
                    ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader = 'Fixed Income Plan Statement (Individual): ';
      let pdfName =
        'Fixed_Income_Plan_Statement_' +
        user.name +
        '_' +
        user.surname +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []], // All product data is populated in first column ...
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }

      this.loaderService.updateLoader(false);
    } catch (err) {
      this.loaderService.updateLoader(false);
    }
  }

  // Accumulation Statement
  async generateZeroStatement(
    loans: any,
    user: any,
    assetPrices: any,
    download: boolean
  ) {
    try {
      this.loaderService.updateLoader(true);
      let ethMarketPrice = assetPrices.find(
        (x) => x.symbol === 'ETHUSDT'
      ).price;
      let btcMarketPrice = assetPrices.find(
        (x) => x.symbol === 'BTCUSDT'
      ).price;
      let usdcMarketPrice = assetPrices.find(
        (x) => x.symbol === 'USDCUSDT'
      ).price;
      let baccMarketPrice = assetPrices.find(
        (x) => x.symbol === 'BACCUSDT'
      ).price;
      let paymentArray = [];
      let totalsArray = [];

      // Statement Header
      let statementHeader = 'Accumulation Fund Statement: ';
      let pdfName =
        'Accumulation_Fund_Statement_' +
        user.name +
        '_' +
        user.surname +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let totalInvested = 0;
      let totalBtcCoins = 0;
      let totalEthCoins = 0;
      let totalBascCoins = 0;
      let totalBaccCoins = 0;

      loans.forEach((loan) => {
        totalInvested += loan.initialInvestment;

        switch (loan.coinType) {
          case 'bitcoin':
            totalBtcCoins += loan.totalCoins;
            break;
          case 'ethereum':
            totalEthCoins += loan.totalCoins;
            break;
          case 'basc':
            totalBascCoins += loan.totalCoins;
            break;
          case 'bacc':
            totalBaccCoins += loan.totalCoins;
            break;

          default:
            break;
        }
      });

      totalsArray.push([
        { text: 'Total Deposit' },
        {
          text: parseFloat(totalInvested.toString()).toFixed(2) + ' USDT',
          alignment: 'right',
        },
      ]);

      if (parseFloat(totalBtcCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (BTC)', bold: true },
            {
              text: parseFloat(totalBtcCoins.toString()).toFixed(8) + ' BTC',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalBtcCoins * btcMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      if (parseFloat(totalEthCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (ETH)', bold: true },
            {
              text: parseFloat(totalEthCoins.toString()).toFixed(8) + ' ETH',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalEthCoins * ethMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      if (parseFloat(totalBascCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (BASC)', bold: true },
            {
              text: parseFloat(totalBascCoins.toString()).toFixed(8) + ' BASC',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalBascCoins * usdcMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      if (parseFloat(totalBaccCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (BACC)', bold: true },
            {
              text: parseFloat(totalBaccCoins.toString()).toFixed(8) + ' BASC',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalBaccCoins * baccMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      let docDefinition = {
        footer: {}, // leave open for now ...
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',

        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: false,
            columns: [
              [
                {
                  text: ' ',
                },
                {
                  text: 'Loan Summary',
                  style: 'sectionHeader',
                  alignment: 'left',
                },
                {
                  table: {
                    widths: [170, '*'],
                    dontBreakRows: false,
                    headerRows: totalsArray.length,
                    keepWithHeaderRows: totalsArray.length,
                    body: [
                      [
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                      ],
                      ...totalsArray,
                    ],
                  },
                },
              ],
              [],
            ],
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === false) {
        pdfMake.createPdf(docDefinition).open();
      } else {
        pdfMake.createPdf(docDefinition).download(pdfName);
      }

      this.loaderService.updateLoader(false);
    } catch (err) {
      this.loaderService.updateLoader(false);
    }
  }

  // Loan Statement
  async generateLoanStatement(
    loans: any,
    user: any,
    assetPrices: any,
    download: boolean
  ) {
    try {
      this.loaderService.updateLoader(true);

      let firstLineItemArray =
        '# No,Date,USDT Deposits,Coin,Product/Plan,Total Assets,Current Asset Price (USDT),Loan LTV Liquidation (USDT),Loan Amount (USDT),Outstanding Loan Balance (USDT),Repaid Loan Amount (USDT),Percentage Loan Repaid'.split(
          ','
        );

      let ethMarketPrice = assetPrices.find(
        (x) => x.symbol === 'ETHUSDT'
      ).price;
      let btcMarketPrice = assetPrices.find(
        (x) => x.symbol === 'BTCUSDT'
      ).price;
      let usdcMarketPrice = assetPrices.find(
        (x) => x.symbol === 'USDCUSDT'
      ).price;
      let baccMarketPrice = assetPrices.find(
        (x) => x.symbol === 'BACCUSDT'
      ).price;

      let paymentArray = [];
      let totalsArray = [];

      // Statement Header
      let statementHeader = 'Digital Asset Loan Statement: ';
      let pdfName =
        'Loan_Statement_' +
        user.name +
        '_' +
        user.surname +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let totalInvested = 0;
      let totalLoaned = 0;
      let totalRepaid = 0;
      let totalOutstanding = 0;
      let totalBtcCoins = 0;
      let totalEthCoins = 0;
      let totalBascCoins = 0;
      let totalBaccCoins = 0;
      let totalLiquidatedOutstanding = 0;

      loans.forEach((loan) => {
        // Pierre wants total repaid overall to show
        totalRepaid += loan.repaidAmount;

        if (loan.settled === false && loan.liquidated === false) {
          totalInvested += loan.initialInvestment;
          totalLoaned += loan.loanAmount;
          totalOutstanding += loan.outstandingBalance;

          switch (loan.coinType) {
            case 'bitcoin':
              totalBtcCoins += loan.totalCoins;
              break;
            case 'ethereum':
              totalEthCoins += loan.totalCoins;
              break;
            case 'basc':
              totalBascCoins += loan.totalCoins;
              break;
            case 'bacc':
              totalBaccCoins += loan.totalCoins;
              break;
            default:
              break;
          }
        }

        // Liquidated loan amount outstanding
        if (loan.settled === false && loan.liquidated === true) {
          totalLiquidatedOutstanding += loan.outstandingBalance;
        }
      });

      for (let index = 0; index < loans.length; index++) {
        let loanItem = loans[index];

        let ltvColor = '#0065A0';
        if (loanItem.liquidated === true) {
          ltvColor = '#E74C3C';
        }

        if (loanItem.settled === true) {
          ltvColor = '#5AA454';
        }

        let contentItem = [
          { text: loanItem._id.slice(loanItem._id.length - 8).toUpperCase() },
          {
            text: this.datePipe.transform(
              new Date(loanItem.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          { text: parseFloat(loanItem.initialInvestment).toFixed(2) },
          { text: '' }, // ETH or BTC
          { text: loanItem.productType?.toUpperCase() }, // flexi, zero, etc.
          { text: parseFloat(loanItem.totalCoins).toFixed(8) },
          { text: parseFloat(loanItem.currentCoinValue).toFixed(2) },
          { text: parseFloat(loanItem.ltvValue).toFixed(2), color: ltvColor },
          { text: parseFloat(loanItem.loanAmount).toFixed(2) },
          {
            text: parseFloat(loanItem.outstandingBalance).toFixed(2),
          },
          { text: parseFloat(loanItem.repaidAmount).toFixed(2) },
          {
            text:
              ((loanItem.repaidAmount / loanItem.loanAmount) * 100).toFixed(2) +
              '%',
          },
        ];

        if (loanItem?.isZeroLoan === true) {
          contentItem[10].text = 'N/A';
          contentItem[11].text = 'N/A';
        }

        paymentArray.push(contentItem);

        // Calculate the Totals table
        switch (loanItem?.coinType) {
          case 'ethereum': {
            paymentArray[index][3].text = 'ETH';
            break;
          }

          case 'bitcoin': {
            paymentArray[index][3].text = 'BTC';
            break;
          }

          case 'basc': {
            paymentArray[index][3].text = 'BASC';
            break;
          }

          case 'bacc': {
            paymentArray[index][3].text = 'BACC';
            break;
          }
        }
      }

      totalsArray.push(
        [
          { text: 'Total Deposit' },
          {
            text: parseFloat(totalInvested.toString()).toFixed(2) + ' USDT',
            alignment: 'right',
          },
        ],
        [
          { text: 'Total Loan Amounts' },
          {
            text: parseFloat(totalLoaned.toString()).toFixed(2) + ' USDT',
            alignment: 'right',
          },
        ]
      );

      if (parseFloat(totalBtcCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (BTC)', bold: true },
            {
              text: parseFloat(totalBtcCoins.toString()).toFixed(8) + ' BTC',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalBtcCoins * btcMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      if (parseFloat(totalEthCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (ETH)', bold: true },
            {
              text: parseFloat(totalEthCoins.toString()).toFixed(8) + ' ETH',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalEthCoins * ethMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      if (parseFloat(totalBascCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (BASC)', bold: true },
            {
              text: parseFloat(totalBascCoins.toString()).toFixed(8) + ' BASC',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalBascCoins * usdcMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      if (parseFloat(totalBaccCoins.toString()) > 0) {
        totalsArray.push(
          [
            { text: 'Total BA Funds Purchased (BACC)', bold: true },
            {
              text: parseFloat(totalBaccCoins.toString()).toFixed(8) + ' BACC',
              alignment: 'right',
              bold: true,
            },
          ],
          [
            { text: 'Value of BA Funds at market price' },
            {
              text: (totalBaccCoins * baccMarketPrice).toFixed(2) + ' USDT',
              alignment: 'right',
            },
          ]
        );
      }

      if (totalLiquidatedOutstanding > 0) {
        totalsArray.push([
          { text: 'Liquidation Repayment Outstanding Amount' },
          {
            text:
              parseFloat(totalLiquidatedOutstanding.toString()).toFixed(2) +
              ' USDT',
            alignment: 'right',
          },
        ]);
      }

      totalsArray.push(
        [
          { text: 'Total Outstanding Loan Balance' },
          {
            text: parseFloat(totalOutstanding.toString()).toFixed(2) + ' USDT',
            alignment: 'right',
          },
        ],
        [
          { text: 'Total Loan Repayment Amount', bold: true },
          {
            text: parseFloat(totalRepaid.toString()).toFixed(2) + ' USDT',
            alignment: 'right',
            bold: true,
          },
        ]
      );

      let docDefinition = {
        footer: {
          columns: [
            {},
            {
              text: 'Open',
              alignment: 'middle',
              width: 25,
              color: '#0065A0',
            },
            { text: '|', alignment: 'middle', width: 10 },
            {
              text: 'Liquidated',
              alignment: 'middle',
              width: 45,
              color: '#E74C3C',
            },
            { text: '|', alignment: 'middle', width: 10 },
            {
              text: 'Settled',
              alignment: 'middle',
              width: 40,
              color: '#5AA454',
            },
            {},
          ],
        },
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',

        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            columns: [
              [
                {
                  text: 'Customer Details',
                  style: 'sectionHeader',
                },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: false,
            columns: [
              [
                {
                  text: 'Loan Summary',
                  style: 'sectionHeader',
                  alignment: 'left',
                  pageBreak: 'before',
                },
                {
                  table: {
                    widths: [170, '*'],
                    dontBreakRows: false,
                    headerRows: totalsArray.length,
                    keepWithHeaderRows: totalsArray.length,
                    body: [
                      [
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                        {
                          border: [false, true, false, false],
                          fillColor: '#a9a9a9',
                          text: '',
                        },
                      ],
                      ...totalsArray,
                    ],
                  },
                },
              ],
              [],
            ],
          },
          {
            text: 'Leverage/Collateral Details',
            style: 'sectionHeader',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [40, 44, '*', 25, 30, '*', '*', '*', '*', '*', '*', 35],

              body: [firstLineItemArray, ...paymentArray],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf

      if (download === false) {
        pdfMake.createPdf(docDefinition).open();
      } else {
        pdfMake.createPdf(docDefinition).download(pdfName);
      }

      this.loaderService.updateLoader(false);
    } catch (err) {
      console.log(err);
      this.loaderService.updateLoader(false);
    }
  }

  async generateLeverageStatementGroup(
    group: any,
    members: any,
    loans: any,
    download: boolean
  ) {
    try {
      this.loaderService.updateLoader(true);

      var firstLineItemArray =
        '# No,Date,USDT Deposits,Coin,Assets Purchased,LTV,Original Asset Purchase Price (USDT),Loan Amount (USDT),Outstanding Loan Balance (USDT),Repaid Loan Amount (USDT),Percentage Loan Repaid'.split(
          ','
        );

      let loanItemsArray = [];
      let summaryTotals = {
        totalInvested: 0,
        totalLoan: 0,
        totalOutstanding: 0,
        totalRepaid: 0,
      };

      for (let index = 0; index < loans.length; index++) {
        let loanItem = loans[index];

        if (loanItem.settled === false) {
          summaryTotals.totalInvested += loanItem.initialInvestment;
          summaryTotals.totalLoan += loanItem.loanAmount;
          summaryTotals.totalOutstanding += loanItem.outstandingBalance;
        }

        if (loanItem.repaidAmount > 0) {
          summaryTotals.totalRepaid += loanItem.repaidAmount;
        }

        let ltvColor = '#0065A0';

        if (loanItem.settled === true) {
          ltvColor = '#5AA454';
        }

        let contentItem = [
          {
            text: loanItem._id.slice(loanItem._id.length - 8).toUpperCase(),
            color: ltvColor,
          },
          {
            text: this.datePipe.transform(
              new Date(loanItem.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          { text: parseFloat(loanItem.initialInvestment).toFixed(2) },
          { text: loanItem.coinType.toUpperCase() }, // BASC or BAIF
          { text: parseFloat(loanItem.totalCoins).toFixed(8) },
          { text: parseInt(loanItem.loanPercentage) + '%' },
          { text: parseFloat(loanItem.originalCoinValue).toFixed(4) },
          { text: parseFloat(loanItem.loanAmount).toFixed(2) },
          {
            text: parseFloat(loanItem.outstandingBalance).toFixed(2),
          },
          { text: parseFloat(loanItem.repaidAmount).toFixed(2) },
          {
            text:
              ((loanItem.repaidAmount / loanItem.loanAmount) * 100).toFixed(2) +
              '%',
            color: ltvColor,
          },
        ];

        loanItemsArray.push(contentItem);
      }

      let productData = [
        { text: ' ' },
        {
          text: 'Group Leverage Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested (USDT)' },
                {
                  text: summaryTotals.totalInvested?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Loan (USDT)' },
                {
                  text: summaryTotals.totalLoan?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Outstanding (USDT)' },
                {
                  text: summaryTotals.totalOutstanding?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Repaid (USDT)' },
                {
                  text: summaryTotals.totalRepaid?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader =
        'Group Leverage Plan Statement: ' +
        this.datePipe.transform(new Date(), 'd MMMM y');
      let pdfName =
        'Group_Leverage_Statement_' +
        group.name?.replace(/ /g, '_') +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let groupType = 'internal';

      if (group.isBlueAssetGroup === false) {
        groupType = 'external';
      }

      let docDefinition = {
        footer: {
          columns: [
            {},
            {
              text: 'Open',
              alignment: 'middle',
              width: 25,
              color: '#0065A0',
            },
            { text: '|', alignment: 'middle', width: 10 },
            {
              text: 'Liquidated',
              alignment: 'middle',
              width: 45,
              color: '#E74C3C',
            },
            { text: '|', alignment: 'middle', width: 10 },
            {
              text: 'Settled',
              alignment: 'middle',
              width: 40,
              color: '#5AA454',
            },
            {},
          ],
        },
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text: statementHeader,
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Group Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Group Name', group.name],
                      ['Group Email', group.email],
                      ['Group Tel', group.contactNumber],
                      ['Group ROI', group.returnInvestment?.toFixed(2) + '%'],
                      ['Group Funds Type', group.preferredAsset?.toUpperCase()],
                      ['Group', groupType.toUpperCase()],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []],
          },
          {
            text: 'Leverage Breakdown',
            style: 'sectionHeader',
            pageBreak: 'before',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [40, 44, 35, 25, '*', '*', '*', '*', '*', '*', '*'],
              body: [firstLineItemArray, ...loanItemsArray],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      this.loaderService.updateLoader(false);

      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // For user on the portfolio page index flexi group
  async generateFlexiIndexStatement(member: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);

      let productData = [
        { text: ' ' },
        {
          text: 'Flexi Summary (IndexFund)',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Flexi (USDT)' },
                {
                  text: member.flexi.currentInvestment?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Allowed To Withdraw (USDT)' },
                {
                  text: member.flexi.allowedToWithdraw?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: member.flexi.payOutType?.toUpperCase(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Payout (USDT)' },
                {
                  text: member.flexi.incomeForMonth?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let loanData = [
        { text: ' ' },
        {
          text: 'Leverage Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Loan (USDT)' },
                {
                  text: member.loan.totalInvested?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Outstanding Loan (USDT)' },
                {
                  text: member.loan.totalOutstanding?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let assetData = [
        { text: ' ' },
        {
          text: 'BA Funds Summary',
          style: 'sectionHeader',
          alignment: 'left',
          pageBreak: 'before',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total BASC' },
                {
                  text: member.assets.totalBasc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BTC' },
                {
                  text: member.assets.totalBtc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total ETH' },
                {
                  text: member.assets.totalEth?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BACC' },
                {
                  text: member.assets.totalBacc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total TUSD' },
                {
                  text: member.assets.totalTusd?.toFixed(8),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader =
        'Flexi Plan Statement (IndexFund): ' +
        this.datePipe.transform(new Date(), 'd MMMM y');
      let pdfName =
        'Flexi_Plan_Statement_Index_Fund' +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text: statementHeader,
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                {
                  table: {
                    headerRows: 1,
                    body: [
                      [
                        'Your Name',
                        (member.name || 'Unknown') +
                          ' ' +
                          (member.surname || 'Unknown'),
                      ],
                      ['Your Email', member.email],
                      ['Your Cell', member.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...loanData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...assetData], []],
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      this.loaderService.updateLoader(false);

      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // For user on the portfolio page retire flexi group
  async generateFlexiSportStatement(member: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);

      let productData = [
        { text: ' ' },
        {
          text: 'Flexi Summary (Sport)',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Flexi (USDT)' },
                {
                  text: member.flexi.currentInvestment?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Allowed To Withdraw (USDT)' },
                {
                  text: member.flexi.allowedToWithdraw?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: member.flexi.payOutType?.toUpperCase(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Payout (USDT)' },
                {
                  text: member.flexi.incomeForMonth?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let loanData = [
        { text: ' ' },
        {
          text: 'Leverage Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Loan (USDT)' },
                {
                  text: member.loan.totalInvested?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Outstanding Loan (USDT)' },
                {
                  text: member.loan.totalOutstanding?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let assetData = [
        { text: ' ' },
        {
          text: 'Funds Summary',
          style: 'sectionHeader',
          alignment: 'left',
          pageBreak: 'before',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total BASC' },
                {
                  text: member.assets.totalBasc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BTC' },
                {
                  text: member.assets.totalBtc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total ETH' },
                {
                  text: member.assets.totalEth?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BACC' },
                {
                  text: member.assets.totalBacc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total TUSD' },
                {
                  text: member.assets.totalTusd?.toFixed(8),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader =
        'Flexi Plan Statement (Sport): ' +
        this.datePipe.transform(new Date(), 'd MMMM y');
      let pdfName =
        'Flexi_Plan_Statement_Sport' +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text: statementHeader,
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                {
                  table: {
                    headerRows: 1,
                    body: [
                      [
                        'Your Name',
                        (member.name || 'Unknown') +
                          ' ' +
                          (member.surname || 'Unknown'),
                      ],
                      ['Your Email', member.email],
                      ['Your Cell', member.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...loanData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...assetData], []],
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      this.loaderService.updateLoader(false);

      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // For user on the portfolio page retire flexi group
  async generateFlexiRetireStatement(member: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);

      let productData = [
        { text: ' ' },
        {
          text: 'Flexi Summary (Retirement)',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Flexi (USDT)' },
                {
                  text: member.flexi.currentInvestment?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Allowed To Withdraw (USDT)' },
                {
                  text:
                    member.flexi.allowedToWithdraw?.toFixed(2) +
                    ' ' +
                    '(NOT ALLOWED)',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: member.flexi.payOutType?.toUpperCase(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Payout (USDT)' },
                {
                  text: member.flexi.incomeForMonth?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let loanData = [
        { text: ' ' },
        {
          text: 'Leverage Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Loan (USDT)' },
                {
                  text: member.loan.totalInvested?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Outstanding Loan (USDT)' },
                {
                  text: member.loan.totalOutstanding?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let assetData = [
        { text: ' ' },
        {
          text: 'BA Funds Summary',
          style: 'sectionHeader',
          alignment: 'left',
          pageBreak: 'before',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total BASC' },
                {
                  text: member.assets.totalBasc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BTC' },
                {
                  text: member.assets.totalBtc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total ETH' },
                {
                  text: member.assets.totalEth?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BACC' },
                {
                  text: member.assets.totalBacc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total TUSD' },
                {
                  text: member.assets.totalTusd?.toFixed(8),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader =
        'Flexi Plan Statement (Retirement): ' +
        this.datePipe.transform(new Date(), 'd MMMM y');
      let pdfName =
        'Flexi_Plan_Statement_Retirement' +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text: statementHeader,
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                {
                  table: {
                    headerRows: 1,
                    body: [
                      [
                        'Your Name',
                        (member.name || 'Unknown') +
                          ' ' +
                          (member.surname || 'Unknown'),
                      ],
                      ['Your Email', member.email],
                      ['Your Cell', member.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...loanData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...assetData], []],
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      this.loaderService.updateLoader(false);

      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // For user on the portfolio page stable flexi group
  async generateFlexiStableStatement(member: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);

      let productData = [
        { text: ' ' },
        {
          text: 'Flexi Summary (Stable)',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Flexi (USDT)' },
                {
                  text: member.flexi.currentInvestment?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Allowed To Withdraw (USDT)' },
                {
                  text: member.flexi.allowedToWithdraw?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: member.flexi.payOutType?.toUpperCase(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Payout (USDT)' },
                {
                  text: member.flexi.incomeForMonth?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let loanData = [
        { text: ' ' },
        {
          text: 'Leverage Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Loan (USDT)' },
                {
                  text: member.loan.totalInvested?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Outstanding Loan (USDT)' },
                {
                  text: member.loan.totalOutstanding?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let assetData = [
        { text: ' ' },
        {
          text: 'BA Funds Summary',
          style: 'sectionHeader',
          alignment: 'left',
          pageBreak: 'before',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total BASC' },
                {
                  text: member.assets.totalBasc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BTC' },
                {
                  text: member.assets.totalBtc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total ETH' },
                {
                  text: member.assets.totalEth?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BACC' },
                {
                  text: member.assets.totalBacc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total TUSD' },
                {
                  text: member.assets.totalTusd?.toFixed(8),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader =
        'Flexi Plan Statement (Stable): ' +
        this.datePipe.transform(new Date(), 'd MMMM y');
      let pdfName =
        'Flexi_Plan_Statement_Stable' +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text: statementHeader,
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                {
                  table: {
                    headerRows: 1,
                    body: [
                      [
                        'Your Name',
                        (member.name || 'Unknown') +
                          ' ' +
                          (member.surname || 'Unknown'),
                      ],
                      ['Your Email', member.email],
                      ['Your Cell', member.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...loanData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...assetData], []],
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      this.loaderService.updateLoader(false);

      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  async generateFlexiStatementGroupMember(
    group: any,
    member: any,
    download: boolean
  ) {
    try {
      this.loaderService.updateLoader(true);

      let productData = [
        { text: ' ' },
        {
          text: 'Member Flexi Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Flexi (USDT)' },
                {
                  text: member.flexi.currentInvestment?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Allowed To Withdraw (USDT)' },
                {
                  text: member.flexi.allowedToWithdraw?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: member.flexi.payOutType?.toUpperCase(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Payout (USDT)' },
                {
                  text: member.flexi.incomeForMonth?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let loanData = [
        { text: ' ' },
        {
          text: 'Member Loan Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Loan (USDT)' },
                {
                  text: member.loan.totalInvested?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Outstanding Loan (USDT)' },
                {
                  text: member.loan.totalOutstanding?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      let assetData = [
        { text: ' ' },
        {
          text: 'Member BA Funds Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total BASC' },
                {
                  text: member.assets.totalBasc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BTC' },
                {
                  text: member.assets.totalBtc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total ETH' },
                {
                  text: member.assets.totalEth?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total BACC' },
                {
                  text: member.assets.totalBacc?.toFixed(8),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total TUSD' },
                {
                  text: member.assets.totalTusd?.toFixed(8),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader =
        'Group Member Flexi Plan Statement: ' +
        this.datePipe.transform(new Date(), 'd MMMM y');
      let pdfName =
        'Group_Member_Flexi_Plan_Statement_' +
        group.name?.replace(/ /g, '_') +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text: statementHeader,
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Member Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Group Name', group.name],
                      ['Group ROI', group.returnInvestment?.toFixed(2) + '%'],
                      ['Group Funds Type', group.preferredAsset?.toUpperCase()],
                      [
                        'Your Name',
                        (member.name || 'Unknown') +
                          ' ' +
                          (member.surname || 'Unknown'),
                      ],
                      ['Your Email', member.email],
                      ['Your Cell', member.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...loanData], []],
          },
          {
            dontBreakRows: true,
            columns: [[...assetData], []],
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      this.loaderService.updateLoader(false);

      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  async generateFlexiStatementGroup(
    group: any,
    members: any,
    download: boolean
  ) {
    try {
      this.loaderService.updateLoader(true);

      var firstLineItemArray =
        'Unique_ID,Name,Surname,Cell,Email,Total Loan Invested,Total Loan Outstanding,Payout Type,Current Invested (Flexi),Allowed Withdrawal,Monthly Payout'.split(
          ','
        );

      let membersLineItems = [];
      let summaryTotals = {
        totalInvested: 0,
        totalWithdraw: 0,
        totalDeferred: 0,
        totalWallet: 0,
        totalPayout: 0,
      };

      members?.sort(
        (a, b) => b.flexi.currentInvestment - a.flexi.currentInvestment
      );

      for (let index = 0; index < members.length; index++) {
        let member = members[index];

        summaryTotals.totalInvested += member.flexi.currentInvestment;
        summaryTotals.totalPayout += member.flexi.incomeForMonth;
        summaryTotals.totalWithdraw += member.flexi.allowedToWithdraw;

        if (member.flexi.payOutType === 'wallet') {
          summaryTotals.totalWallet++;
        }

        if (member.flexi.payOutType === 'deferred') {
          summaryTotals.totalDeferred++;
        }

        let contentItem = [
          {
            text: member.unique_id,
          },
          {
            text: member?.name || 'Unknown',
          },
          {
            text: member?.surname || 'Unknown',
          },
          {
            text: member?.cell || 'Unknown',
          },
          {
            text: member.email,
          },
          { text: parseFloat(member.loan.totalInvested).toFixed(2) },
          { text: parseFloat(member.loan.totalOutstanding).toFixed(2) },
          {
            text: member.flexi.payOutType?.toUpperCase(),
          },
          { text: parseFloat(member.flexi.currentInvestment).toFixed(2) },
          { text: parseFloat(member.flexi.allowedToWithdraw).toFixed(2) },
          {
            text: parseFloat(member.flexi.incomeForMonth).toFixed(2),
          },
        ];

        membersLineItems.push(contentItem);
      }

      let productData = [
        { text: ' ' },
        {
          text: 'Flexi Group Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: 0,
            keepWithHeaderRows: 0,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Total Invested Flexi (USDT)' },
                {
                  text: summaryTotals.totalInvested?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Withdraw Allowed (USDT)' },
                {
                  text: summaryTotals.totalWithdraw?.toFixed(2),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Members' },
                {
                  text: members.length,
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Deferred Payouts' },
                {
                  text: summaryTotals.totalDeferred,
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Wallet Payouts' },
                {
                  text: summaryTotals.totalWallet,
                  alignment: 'right',
                },
              ],
              [
                { text: 'Total Payout (USDT)' },
                {
                  text: summaryTotals.totalPayout?.toFixed(2),
                  alignment: 'right',
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader =
        'Group Flexi Plan Statement: ' +
        this.datePipe.transform(new Date(), 'd MMMM y');
      let pdfName =
        'Group_Flexi_Plan_Statement_' +
        group.name?.replace(/ /g, '_') +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let groupType = 'internal';

      if (group.isBlueAssetGroup === false) {
        groupType = 'external';
      }

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text: statementHeader,
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Group Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Group Name', group.name],
                      ['Group Email', group.email],
                      ['Group Tel', group.contactNumber],
                      ['Group ROI', group.returnInvestment?.toFixed(2) + '%'],
                      ['Group Funds Type', group.preferredAsset?.toUpperCase()],
                      ['Group', groupType.toUpperCase()],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []],
          },
          {
            text: 'Flexi Individual Breakdown',
            style: 'sectionHeader',
            pageBreak: 'before',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [90, 50, 50, 60, 90, '*', '*', '*', '*', '*', '*'],
              body: [firstLineItemArray, ...membersLineItems],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      this.loaderService.updateLoader(false);

      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }
    } catch (error) {
      this.loaderService.updateLoader(false);
    }
  }

  // Flexi Statement
  async generateFlexiStatement(user: any, product: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);

      let totalsArray = [];
      let depositArray = [];

      var firstLineItemArray =
        '# No,Date,USDT Deposits,Percentage Return,Potential Monthly Income,Actual Monthly Income'.split(
          ','
        );

      let initialInvestment = 0;
      let currentInvestment = 0;
      let incomeForMonth = 0;
      let monthlyReturn = 0;

      for (let index = 0; index < product.length; index++) {
        const flexiBlock = product[index];

        initialInvestment += +flexiBlock.initialInvestment;
        currentInvestment += +flexiBlock.currentInvestment;

        if (index === 0) {
          incomeForMonth += +flexiBlock.incomeForMonth;
        }

        let monthlyReturnItem =
          (+flexiBlock.monthlyRevenueCurrent / 100) *
          +flexiBlock.currentInvestment;
        monthlyReturn += monthlyReturnItem;

        let contentItem = [
          {
            text: flexiBlock._id.slice(flexiBlock._id.length - 8).toUpperCase(),
          },
          {
            text: this.datePipe.transform(
              new Date(flexiBlock.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          { text: parseFloat(flexiBlock.currentInvestment).toFixed(2) },
          {
            text: parseFloat(flexiBlock.monthlyRevenueCurrent).toFixed(3) + '%',
          },

          {
            text: monthlyReturnItem.toFixed(2),
          },
          { text: parseFloat(flexiBlock.incomeForMonth).toFixed(2) },
        ];

        depositArray.push(contentItem);
      }

      // We add income to product section
      let productData = [
        { text: ' ' },
        {
          text: 'Flexi Income Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Initial Total Deposit' },
                {
                  text: initialInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Current Total Deposit' },
                {
                  text: currentInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Income Date' },
                {
                  text: '25th of each month',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: product[0]?.payOutType?.toUpperCase(),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Previous Month Payout (Includes Pro Rata)' },
                {
                  text: incomeForMonth?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Monthly Return' },
                {
                  text: monthlyReturn?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader = 'Flexi Income Plan: ';
      let pdfName =
        'Flexi_Income' +
        '_' +
        user.name +
        '_' +
        user.surname +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []], // All product data is populated in first column ...
          },
          {
            text: 'Investment Details',
            style: 'sectionHeader',
            pageBreak: 'before',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [40, 44, '*', '*', '*', '*'],

              body: [firstLineItemArray, ...depositArray],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }

      this.loaderService.updateLoader(false);
    } catch (err) {
      this.loaderService.updateLoader(false);
    }
  }

  // Smart Statement
  async generateSmartStatement(user: any, product: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);

      let totalsArray = [];
      let depositArray = [];

      var firstLineItemArray =
        '# No,Date,USDT Deposits,Percentage Return,Potential Monthly Income,Actual Monthly Income'.split(
          ','
        );

      let initialInvestment = 0;
      let currentInvestment = 0;
      let incomeForMonth = 0;
      let monthlyReturn = 0;

      for (let index = 0; index < product.length; index++) {
        const smartBlock = product[index];

        initialInvestment += +smartBlock.initialInvestment;
        currentInvestment += +smartBlock.currentInvestment;

        if (index === 0) {
          incomeForMonth += +smartBlock.incomeForMonth;
        }

        let monthlyReturnItem =
          (+smartBlock.monthlyRevenueCurrent / 100) *
          +smartBlock.currentInvestment;
        monthlyReturn += monthlyReturnItem;

        let contentItem = [
          {
            text: smartBlock._id.slice(smartBlock._id.length - 8).toUpperCase(),
          },
          {
            text: this.datePipe.transform(
              new Date(smartBlock.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          { text: parseFloat(smartBlock.currentInvestment).toFixed(2) },
          {
            text: parseFloat(smartBlock.monthlyRevenueCurrent).toFixed(3) + '%',
          },

          {
            text: monthlyReturnItem.toFixed(2),
          },
          { text: parseFloat(smartBlock.incomeForMonth).toFixed(2) },
        ];

        depositArray.push(contentItem);
      }

      // We add income to product section
      let productData = [
        { text: ' ' },
        {
          text: 'Flexi Smart Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Initial Total Deposit' },
                {
                  text: initialInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Current Total Deposit' },
                {
                  text: currentInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Income Date' },
                {
                  text: '25th of each month',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: product[0]?.payOutType?.toUpperCase(),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Previous Month Payout (Includes Pro Rata)' },
                {
                  text: incomeForMonth?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Monthly Return' },
                {
                  text: monthlyReturn?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader = 'Flexi Smart Plan: ';
      let pdfName =
        'Flexi_Smart_Plan' +
        '_' +
        user.name +
        '_' +
        user.surname +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []], // All product data is populated in first column ...
          },
          {
            text: 'Investment Details',
            style: 'sectionHeader',
            pageBreak: 'before',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [40, 44, '*', '*', '*', '*'],

              body: [firstLineItemArray, ...depositArray],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }

      this.loaderService.updateLoader(false);
    } catch (err) {
      this.loaderService.updateLoader(false);
    }
  }

  // Retire Statement
  async generateRetireStatement(user: any, product: any, download: boolean) {
    try {
      this.loaderService.updateLoader(true);

      let totalsArray = [];
      let depositArray = [];

      var firstLineItemArray =
        '# No,Date,USDT Deposits,Percentage Return,Potential Monthly Income,Actual Monthly Income'.split(
          ','
        );

      let initialInvestment = 0;
      let currentInvestment = 0;
      let incomeForMonth = 0;
      let monthlyReturn = 0;

      for (let index = 0; index < product.length; index++) {
        const retireBlock = product[index];

        initialInvestment += +retireBlock.initialInvestment;
        currentInvestment += +retireBlock.currentInvestment;

        if (index === 0) {
          incomeForMonth += +retireBlock.incomeForMonth;
        }

        let monthlyReturnItem =
          (+retireBlock.monthlyRevenueCurrent / 100) *
          +retireBlock.currentInvestment;
        monthlyReturn += monthlyReturnItem;

        let contentItem = [
          {
            text: retireBlock._id
              .slice(retireBlock._id.length - 8)
              .toUpperCase(),
          },
          {
            text: this.datePipe.transform(
              new Date(retireBlock.dateAdded),
              'yyyy-MM-dd'
            ),
          },
          { text: parseFloat(retireBlock.currentInvestment).toFixed(2) },
          {
            text:
              parseFloat(retireBlock.monthlyRevenueCurrent).toFixed(3) + '%',
          },

          {
            text: monthlyReturnItem.toFixed(2),
          },
          { text: parseFloat(retireBlock.incomeForMonth).toFixed(2) },
        ];

        depositArray.push(contentItem);
      }

      // We add income to product section
      let productData = [
        { text: ' ' },
        {
          text: 'Retire Plan Summary',
          style: 'sectionHeader',
          alignment: 'left',
        },
        {
          table: {
            widths: [170, '*'],
            dontBreakRows: false,
            headerRows: totalsArray.length,
            keepWithHeaderRows: totalsArray.length,
            body: [
              [
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
                {
                  border: [false, true, false, false],
                  fillColor: '#a9a9a9',
                  text: '',
                },
              ],
              [
                { text: 'Initial Total Deposit' },
                {
                  text: initialInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Current Total Deposit' },
                {
                  text: currentInvestment?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
              [
                { text: 'Income Date' },
                {
                  text: '25th of each month',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Payout Type' },
                {
                  text: product[0]?.payOutType?.toUpperCase(),
                  alignment: 'right',
                },
              ],
              [
                { text: 'Previous Month Payout (Includes Pro Rata)' },
                {
                  text: incomeForMonth?.toFixed(2) + ' USDT',
                  alignment: 'right',
                },
              ],
              [
                { text: 'Monthly Return' },
                {
                  text: monthlyReturn?.toFixed(2) + ' USDT',
                  alignment: 'right',
                  bold: true,
                },
              ],
            ],
          },
        },
      ];

      // Statement Header
      let statementHeader = 'Retire Plan: ';
      let pdfName =
        'Retire_Plan' +
        '_' +
        user.name +
        '_' +
        user.surname +
        '_' +
        this.datePipe.transform(new Date(), 'yyyy_MM_dd') +
        '.pdf';

      let docDefinition = {
        footer: {},
        watermark: {
          text: 'BlueAsset Statement',
          color: '#0065a0',
          opacity: 0.1,
          bold: false,
          italics: false,
        },

        pageOrientation: 'landscape',
        content: [
          {
            image: this.logo,
            width: 200,
            alignment: 'left',
          },
          {
            color: '#C5C5C5',
            margin: [20, 15, 20, 15],
            columns: [
              [
                { text: 'SOUTH AFRICA', bold: true },
                { text: 'Unit GF08, Block A' },
                { text: 'Southdown Office Park' },
                { text: '22 Karee Street' },
                { text: 'Irene (Ground Floor)' },
                { text: 'Centurion' },
              ],
              [
                { text: 'CAYMAN ISLANDS', bold: true, alignment: 'right' },
                { text: 'PO Box 2775', alignment: 'right' },
                { text: '67 Fort Street', alignment: 'right' },
                { text: 'Artemis House', alignment: 'right' },
                { text: 'George Town', alignment: 'right' },
                { text: 'Grand Cayman', alignment: 'right' },
              ],
            ],
          },
          {
            text:
              statementHeader + this.datePipe.transform(new Date(), 'd MMMM y'),
            fontSize: 16,
            alignment: 'center',
            color: '#0065a0',
          },
          {
            text: 'Customer Details',
            style: 'sectionHeader',
          },
          {
            columns: [
              [
                // 1
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ['Name', user.name + ' ' + user.surname],
                      ['Address', user.address?.street],
                      ['', user.address?.suburb],
                      ['', user.address?.city],
                      ['', user.address?.country],
                      ['Email', user.email],
                      ['Contact', user.cell],
                    ],
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          {
            dontBreakRows: true,
            columns: [[...productData], []], // All product data is populated in first column ...
          },
          {
            text: 'Investment Details',
            style: 'sectionHeader',
            pageBreak: 'before',
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: [40, 44, '*', '*', '*', '*'],

              body: [firstLineItemArray, ...depositArray],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: 'underline',
            fontSize: 14,
            margin: [0, 15, 0, 15],
            color: '#0065a0',
          },
        },
        defaultStyle: {
          fontSize: '8',
        },
      };

      // Generate the pdf
      if (download === false) {
        await pdfMake.createPdf(docDefinition).open();
      } else {
        await pdfMake.createPdf(docDefinition).download(pdfName);
      }

      this.loaderService.updateLoader(false);
    } catch (err) {
      this.loaderService.updateLoader(false);
    }
  }
}

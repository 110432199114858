import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private updatePasswordKey = 'updatepassword';
  private enabledisabletwofaKey = 'enabledisabletwofaotp';
  private sendtwofaotpKey = 'sendtwofaotp';
  private verifyandenabletwofaotpKey = 'verifyandenabletwofaotp';
  private enabledisablegoogleauthKey = 'enabledisablegoogleauth';
  private generategoogleauthKey = 'generategoogleauth';
  private getrecoverycodeKey = 'getrecoverycode';

  constructor(private apiService: ApiService) {}

  updatePassword(data) {
    return this.apiService.post(this.updatePasswordKey, data);
  }

  enableDisableTwoFaOtp(data) {
    return this.apiService.post(this.enabledisabletwofaKey, data);
  }

  enableDisableGoogleTwoFa(data) {
    return this.apiService.post(this.enabledisablegoogleauthKey, data);
  }

  getRecoveryCode(data) {
    return this.apiService.post(this.getrecoverycodeKey, data);
  }

  sendTwoFaOtp(data) {
    return this.apiService.post(this.sendtwofaotpKey, data);
  }

  generateGoogleAuth(data) {
    return this.apiService.post(this.generategoogleauthKey, data);
  }

  verifyAndEnableTwoFaOtp(data) {
    return this.apiService.post(this.verifyandenabletwofaotpKey, data);
  }
}

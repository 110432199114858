<div class="containers">
  <div class="card-row row">
    <div>
      <div class="security-top-text">
        <h2 class="security-top-text-text">INVESTMENT PRODUCTS</h2>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0" data-animation="fade-up">
        <img
          src="../../assets/images/btc.png"
          class="card-img-top ng-logo card-img-btc"
          alt="..."
        />
        <div class="card-body">
          <h2 class="card-title">BlueAsset BTC Fund</h2>
          <p class="card-text">
            Bitcoin (BTC) is a decentralized digital currency that operates on a
            peer-to-peer network, enabling direct transactions without
            intermediaries. It uses blockchain technology to ensure
            transparency, security, and immutability of transaction records, and
            is considered a valuable investment asset due to its limited supply
            of 21 million coins.
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0" data-animation="fade-up">
        <img
          src="../../assets/images/eth.png"
          class="fireblocks-logo card-img-top card-img-eth"
          alt="..."
        />
        <div class="card-body">
          <h2 class="card-title">BlueAsset ETH Fund</h2>
          <p class="card-text">
            Ethereum (ETH) is a decentralized platform that enables smart
            contracts and decentralized applications (dApps) to be built and
            executed without any downtime or third-party interference. It uses
            blockchain technology to ensure the secure and transparent execution
            of complex agreements and has its own cryptocurrency, Ether, which
            powers transactions and computational services on the network.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card h-100 mb-4 border-0" data-animation="fade-up">
        <img
          src="../../assets/images/usdt.png"
          class="bitfinex-logo card-img-top card-img-usdt"
          alt="..."
        />
        <div class="card-body">
          <h2 class="card-title">BlueAsset Stable Fund</h2>
          <p class="card-text">
            BlueAsset Stable Fund is a cryptocurrency investment fund backed 1:1
            by Tether (USDT), providing investors with a stable and secure way
            to hold and trade digital assets. The fund leverages the price
            stability of USDT, which is pegged to the value of the US dollar,
            ensuring minimal volatility and preserving capital value. With a
            focus on stability, the BlueAsset Stable Fund offers a reliable
            option for those looking to minimize risk in the volatile
            cryptocurrency market.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="card-row card-holder row">
    <div class="card h-100 col-md-3">
      <img src="../../assets/images/btc.png" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">BlueAsset BTC Fund</h5>
        <p class="card-text">
          Bitcoin (BTC) is a decentralized digital currency that operates on a
          peer-to-peer network, enabling direct transactions without
          intermediaries. It uses blockchain technology to ensure transparency,
          security, and immutability of transaction records, and is considered a
          valuable investment asset due to its limited supply of 21 million coins.
        </p>
      </div>
    </div>
    <div class="card h-100 col-md-3">
      <img src="../../assets/images/eth.png" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">BlueAsset ETH Fund</h5>
        <p class="card-text">
          Ethereum (ETH) is a decentralized platform that enables smart contracts
          and decentralized applications (dApps) to be built and executed without
          any downtime or third-party interference. It uses blockchain technology
          to ensure the secure and transparent execution of complex agreements and
          has its own cryptocurrency, Ether, which powers transactions and
          computational services on the network.
        </p>
      </div>
    </div>
    <div class="card h-100 col-md-3">
      <img src="../../assets/images/usdt.png" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">BlueAsset Stable Fund</h5>
        <p class="card-text">
          BlueAsset Stable Fund is a cryptocurrency investment fund backed 1:1 by
          Tether (USDT), providing investors with a stable and secure way to hold
          and trade digital assets. The fund leverages the price stability of
          USDT, which is pegged to the value of the US dollar, ensuring minimal
          volatility and preserving capital value. With a focus on stability, the
          BlueAsset Stable Fund offers a reliable option for those looking to
          minimize risk in the volatile cryptocurrency market.
        </p>
      </div>
    </div>
  </div> -->

<app-cookies class="cookies"></app-cookies>
<div>
  <div *ngIf="hasActiveBanner === false" class="banner-content">
    <div class="hero-holder">
      <div class="content">
        <div class="intro-holder">
          <h1 class="lg-header">
            BECOME YOUR
            <br />
            PERSONAL WHALE
          </h1>
        </div>
        <!-- <app-count-down></app-count-down> -->
        <!-- <div class="list-holder">
          <button class="nav-item">
            <a
              class="nav-link"
              routerLinkActive="active-link active-link-login"
              [ngClass]="{
                blurred: isScrolled
              }"
              (click)="onRegister()"
              >REGISTER</a
            >
            </button>
          <button class="nav-item">
            <a
              class="nav-link"
              [ngClass]="{
                blurred: isScrolled
              }"
              [routerLink]="['/login']"
              routerLinkActive="active-link active-link-login"
              >LOGIN</a
            >
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <app-investments id="investments"></app-investments>
  <app-security id="security"></app-security>
  <app-partners id="partners"></app-partners>
  <app-testimonials id="testimonials"></app-testimonials>
  <app-contact-us id="contact"></app-contact-us>
</div>

<div *ngIf="isLoading" class="loader-api">
  <div class="socket">
    <div class="gel center-gel">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c1 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c2 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c3 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c4 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c5 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c6 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c7 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c8 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c9 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c10 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c11 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c12 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c13 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c14 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c15 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c16 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c17 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c18 r1">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c19 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c20 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c21 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c22 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c23 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c24 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c25 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c26 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c28 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c29 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c30 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c31 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c32 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c33 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c34 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c35 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c36 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c37 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c38 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c39 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c40 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c41 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c42 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c43 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c44 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c45 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c46 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c47 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c48 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c49 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c50 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c51 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c52 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c53 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c54 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c55 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c56 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c57 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c58 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c59 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c60 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c61 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c62 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c63 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c64 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c65 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c66 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c67 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c68 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c69 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c70 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c71 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c72 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c73 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c74 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c75 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c76 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c77 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c78 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c79 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c80 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c81 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c82 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c83 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c84 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c85 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c86 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c87 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c88 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c89 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c90 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c91 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c92 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c93 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c94 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c95 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c96 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c97 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c98 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c99 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c100 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c101 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c102 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c103 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c104 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c105 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c106 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c107 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c108 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c109 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c110 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c111 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <div class="gel c112 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
    <!-- <div class="gel c113 r3">
        <div class="hex-brick h1"></div>
        <div class="hex-brick h2"></div>
        <div class="hex-brick h3"></div>
      </div> -->
    <div class="gel c114 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c115 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c116 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c117 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c118 r2">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c119 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c120 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c121 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c122 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c123 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c124 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c125 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c126 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c127 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>

    <div class="gel c128 r3">
      <div class="hex-brick h1"></div>
      <div class="hex-brick h2"></div>
      <div class="hex-brick h3"></div>
    </div>
  </div>
  <div>
    <p class="loading-text">One moment please...</p>
  </div>
</div>

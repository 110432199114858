<div>
  <div class="determine-holder" *ngIf="!allowUserAdd">
    Determining if you can complete the application...
  </div>
  <div *ngIf="allowUserAdd" class="section-holder">
    <div>
      <h2>New User Application</h2>
      <hr />
    </div>
    <div class="dear-text">
      Dear client - you have been introduced to BlueAsset Group.
    </div>
    <div class="hint mb-1 left-align">
      Please complete the form below to get you started.
    </div>
    <div class="item-h">
      <div>
        <form
          class="password-update-h"
          [formGroup]="userForm"
          (ngSubmit)="addUser()"
        >
          <div class="section-item">
            <div class="section-item-card">
              <div class="statement-download-h">
                <h3>Your Details</h3>
              </div>
              <div
                class="form-group"
                [ngClass]="{
                  'has-danger': isSubmitted && userFormControls.name.errors
                }"
              >
                <label class="form-control-label">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  formControlName="name"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.name.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.name.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.name.errors.required">
                    Name is required!
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label">Surname</label>
                <input
                  type="text"
                  placeholder="Surname"
                  formControlName="surname"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.surname.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.surname.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.surname.errors.required">
                    Surname is required!
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label"
                  >ID/Passport/Company Reg. No</label
                >
                <input
                  type="text"
                  placeholder="ID/Passport/Company Reg. No"
                  formControlName="id"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.id.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.id.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.id.errors.required">
                    ID/Passport/Company Reg. No is required!
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  formControlName="email"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.email.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.email.errors.required">
                    Email is required!
                  </div>
                  <div
                    *ngIf="
                      userFormControls.email.invalid &&
                      userFormControls.email.value?.length > 0
                    "
                  >
                    Email is invalid!
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label">Cell</label>
                <div>
                  <ngx-intl-tel-input
                    [cssClass]="'form-control'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.SouthAfrica"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.International"
                    name="cell"
                    formControlName="cell"
                    [ngClass]="{
                      'is-invalid': isSubmitted && userFormControls.cell.errors
                    }"
                  >
                  </ngx-intl-tel-input>
                  <div
                    *ngIf="isSubmitted && userFormControls.cell.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="userFormControls.cell.errors.required">
                      Cell is required!
                    </div>
                    <div
                      *ngIf="
                        userFormControls.cell.invalid &&
                        userFormControls.cell.value !== null
                      "
                    >
                      Cell is invalid!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-item">
            <div class="section-item-card">
              <div class="statement-download-h">
                <h3>Your Address</h3>
              </div>
              <div class="form-group">
                <label class="form-control-label">Street</label>
                <input
                  type="text"
                  placeholder="Street"
                  formControlName="street"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.street.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.street.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.street.errors.required">
                    Street is required!
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label">Suburb</label>
                <input
                  type="text"
                  placeholder="Suburb"
                  formControlName="suburb"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.suburb.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.suburb.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.suburb.errors.required">
                    Suburb is required!
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label">City</label>
                <input
                  type="text"
                  placeholder="City"
                  formControlName="city"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.city.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.city.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.city.errors.required">
                    City is required!
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label">Province/State</label>
                <input
                  type="text"
                  placeholder="Province"
                  formControlName="province"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted && userFormControls.province.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.province.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.province.errors.required">
                    Province/State is required!
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label">Country</label>
                <select
                  class="form-control"
                  formControlName="country"
                  [ngClass]="{
                    'is-invalid': isSubmitted && userFormControls.country.errors
                  }"
                >
                  <option disabled value="null">
                    -- Please select country --
                  </option>
                  <option value="South Africa">South Africa</option>
                  <option value="Afganistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia & Herzegovina">
                    Bosnia & Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Ter">
                    British Indian Ocean Ter
                  </option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Canary Islands">Canary Islands</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Channel Islands">Channel Islands</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Island">Cocos Island</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote DIvoire">Cote DIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaco">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Ter">
                    French Southern Ter
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Great Britain">Great Britain</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="India">India</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea Sout">Korea South</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Nambia">Nambia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherland Antilles">
                    Netherland Antilles
                  </option>
                  <option value="Netherlands">
                    Netherlands (Holland, Europe)
                  </option>
                  <option value="Nevis">Nevis</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau Island">Palau Island</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Phillipines">Philippines</option>
                  <option value="Pitcairn Island">Pitcairn Island</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Montenegro">
                    Republic of Montenegro
                  </option>
                  <option value="Republic of Serbia">Republic of Serbia</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Barthelemy">St Barthelemy</option>
                  <option value="St Eustatius">St Eustatius</option>
                  <option value="St Helena">St Helena</option>
                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="St Maarten">St Maarten</option>
                  <option value="St Pierre & Miquelon">
                    St Pierre & Miquelon
                  </option>
                  <option value="St Vincent & Grenadines">
                    St Vincent & Grenadines
                  </option>
                  <option value="Saipan">Saipan</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa American">Samoa American</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome & Principe">
                    Sao Tome & Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Erimates">
                    United Arab Emirates
                  </option>
                  <option value="United States of America">
                    United States of America
                  </option>
                  <option value="Uraguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City State">Vatican City State</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (Brit)">
                    Virgin Islands (Brit)
                  </option>
                  <option value="Virgin Islands (USA)">
                    Virgin Islands (USA)
                  </option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <div
                  *ngIf="isSubmitted && userFormControls.country.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.country.errors.required">
                    Country is required!
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-item">
            <div class="section-item-card">
              <div class="statement-download-h">
                <h3>Your Banking Details</h3>
              </div>

              <div class="form-group">
                <label class="form-control-label">Bank Name</label>
                <select
                  class="form-control"
                  formControlName="bankName"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted && userFormControls?.bankName?.errors
                  }"
                  (change)="validateSelectedBank()"
                >
                  <option value="" disabled selected hidden>
                    -- Choose Bank Name --
                  </option>
                  <option
                    *ngFor="let bank of supportedBanks"
                    [ngValue]="bank?.displayName"
                  >
                    {{ bank?.displayName }}
                  </option>
                </select>
                <div
                  *ngIf="isSubmitted && updateFormControls?.bankName?.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="updateFormControls.bankName.errors.required">
                    Bank Name is required!
                  </div>
                </div>
                <div *ngIf="!isBankNameValid" style="color: red">
                  Bank name does not match any available options!
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label">Account Holder Name</label>
                <input
                  type="text"
                  placeholder="Account Holder Name"
                  formControlName="accountHolderName"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted && userFormControls.accountHolderName.errors
                  }"
                />
                <div
                  *ngIf="
                    isSubmitted && userFormControls.accountHolderName.errors
                  "
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="userFormControls.accountHolderName.errors.required"
                  >
                    Account holder is required!
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="form-control-label">Account Number</label>
                <input
                  type="text"
                  placeholder="Account Number"
                  formControlName="accountNumber"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted && userFormControls?.accountNumber?.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.accountNumber.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.accountNumber.errors.required">
                    Account number is required!
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label">Account Type</label>
                <select
                  class="form-control"
                  formControlName="accountType"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted && userFormControls.accountType.errors
                  }"
                  required
                >
                  <option
                    value="null"
                    disabled="true"
                    [selected]="true"
                    style="display: none"
                  >
                    -- Choose Account Type --
                  </option>
                  <option value="Current Account">Current Account</option>
                  <option value="Savings Account">Savings Account</option>
                  <option value="Money Market Account">
                    Money Market Account
                  </option>
                  <option value="Certificate Of Deposit">
                    Certificate Of Deposit
                  </option>
                  <option value="Retirement Account">Retirement Account</option>
                  <option value="Unknown">Other</option>
                </select>

                <div
                  *ngIf="isSubmitted && userFormControls.accountType.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.accountType.errors.required">
                    Account type is required!
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label">Branch Code</label>
                <input
                  type="text"
                  placeholder="Branch Code"
                  formControlName="branchCode"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted && userFormControls.branchCode.errors
                  }"
                />
                <div
                  *ngIf="isSubmitted && userFormControls.branchCode.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.branchCode.errors.required">
                    Branch code is required!
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="form-control-label"
                  >What is your source of funds/wealth?</label
                >
                <select
                  class="form-control"
                  formControlName="fundsSource"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted && userFormControls.fundsSource.errors
                  }"
                  required
                >
                  <option
                    value="null"
                    disabled="true"
                    [selected]="true"
                    style="display: none"
                  >
                    -- Choose Funds Source --
                  </option>
                  <option
                    *ngFor="let item of sourceOfFunds"
                    [value]="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
                <div
                  *ngIf="isSubmitted && userFormControls.fundsSource.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="userFormControls.fundsSource.errors.required">
                    Source of funds is required!
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-item section-item-center">
            <div class="section-item-card-box section-item-center">
              <div class="account-h">
                <div class="submit-logo-h">
                  <img
                    class="submit-logo"
                    src="assets/images/blue_asset_logo_inline_white.png"
                  />
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      formControlName="acceptForm"
                      [ngClass]="{
                        'is-invalid':
                          (isSubmitted && userFormControls.acceptForm.errors) ||
                          userFormControls.acceptForm.value === false
                      }"
                      required
                    />
                    <label
                      class="form-check-label funds-txt"
                      for="flexCheckDefault"
                    >
                      I hereby declare that the information provided is accurate
                      and correct. I also accept all Terms and Conditions from
                      BlueAsset Group.
                    </label>
                    <div
                      *ngIf="
                        (isSubmitted && userFormControls.acceptForm.errors) ||
                        userFormControls.acceptForm.value === false
                      "
                      class="invalid-feedback"
                    >
                      <div *ngIf="userFormControls.acceptForm.errors.required">
                        Please accept declaration!
                      </div>
                    </div>
                  </div>
                </div>
                <div class="date-holder">
                  {{ date | date : "fullDate" }}
                </div>

                <div class="update-h">
                  <!-- <div class="form-group">
                                        <label class=" form-control-label">Referral Code (optional)</label>
                                        <input type="text" placeholder="ABC123DEF" formControlName="referralCode"
                                            class="form-control">
                                        <div class="hint">You can add a referral code if someone referred you.</div>
                                    </div> -->
                  <div class="btn-holder">
                    <button class="btn btn-primary full-btn" type="submit">
                      Submit Application
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Generic Modal Popup -->
<ng-template #genericModal>
  <div class="modal-header">
    <h4 *ngIf="!isGenericError" class="modal-title pull-left">Success</h4>
    <h4 *ngIf="isGenericError" class="modal-title pull-left">Error</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="hideModal()"
    ></button>
  </div>
  <div class="modal-body">
    {{ genericMessage }}
  </div>
  <div class="modal-footer">
    <button
      *ngIf="isGenericError === false"
      type="button"
      class="btn btn-primary"
      (click)="hideModal()"
    >
      Close
    </button>
    <button
      *ngIf="isGenericError === true"
      type="button"
      class="btn btn-danger"
      (click)="hideModal()"
    >
      Close
    </button>
  </div>
</ng-template>

<!-- Referral Modal Popup -->
<ng-template #referralLinkCopiedModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Referral Link Copy</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="hideModal()"
    ></button>
  </div>
  <div class="modal-body">
    Your link was copied to the clipboard, please paste it.
  </div>
  <div class="modal-footer">
    <button
      *ngIf="isGenericError === false"
      type="button"
      class="btn btn-primary"
      (click)="hideModal()"
    >
      Close
    </button>
    <button
      *ngIf="isGenericError === true"
      type="button"
      class="btn btn-danger"
      (click)="hideModal()"
    >
      Close
    </button>
  </div>
</ng-template>

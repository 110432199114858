<div class="count-down-timer">
  <p class="count-heading">Official Launch in:</p>
  <div class="wrapper">
    <div class="times">
      <p #days></p>
      <p #hours></p>
      <p #minutes></p>
      <p #seconds></p>
    </div>
    <div class="description">
      <p>Days</p>
      <p>Hours</p>
      <p>Minutes</p>
      <p>Seconds</p>
    </div>
  </div>
</div>

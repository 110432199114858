import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LoginComponent } from './login/login.component';
import { ApiService } from './services/api.service';
import { LoginService } from './services/login.service';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './home/footer/footer.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { AboutUsComponent } from './home/about-us/about-us.component';
import { ContactUsService } from './services/contact-us.service';
import { MenuBarService } from './services/menu-bar.service';
import { SideBarService } from './services/side-bar.service';
import { AccountModule } from './account/account.module';
import { SummaryService } from './services/summary.service';
import { UtilService } from './services/util.service';
import { CountdownModule } from 'ngx-countdown';
import { LoaderService } from './services/loader.service';
import { LoadingModule } from './loading/loading.module';
import { LoansService } from './services/loans.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { SettingsService } from './services/settings.service';
import { ConfigService } from './services/config.service';
import { ClipboardModule } from 'ngx-clipboard';
import { ToolsService } from './services/tools.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { StatementService } from './services/statement.service';
import { IncomeService } from './services/income.service';
import { FlexiService } from './services/flexi.service';
import { AccumulationService } from './services/accumulation.service';
import { DataService } from './services/data.service';
import { TransactionsService } from './services/transactions.service';
import { MembershipComponent } from './membership/membership.component';
import { ProductApplicationService } from './services/product-application.service';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CommissionsService } from './services/commissions.service';
import { InterceptorTokenService } from './services/interceptor-token.service';
import { GiveComponent } from './give/give.component';
import { DiscoverComponent } from './discover/discover.component';
import { ApplyComponent } from './apply/apply.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GroupsService } from './services/groups.service';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { KycService } from './services/kyc.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DocumentsService } from './services/documents.service';
import { SecurityComponent } from './home/security/security.component';
import { PartnersComponent } from './home/partners/partners.component';
import { TestimonialsComponent } from './home/testimonials/testimonials.component';
import { CookiesComponent } from './home/cookies/cookies.component';
import { InvestmentsComponent } from './home/investments/investments.component';
import { WavesComponent } from './home/footer/waves/waves.component';
import { CountDownComponent } from './home/count-down/count-down.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    MembershipComponent,
    GiveComponent,
    DiscoverComponent,
    ApplyComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    CookiesComponent,
    CountDownComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxChartsModule,
    ModalModule.forRoot(),
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgOtpInputModule,
    CountdownModule,
    LoadingModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    ClipboardModule,
    SignaturePadModule,
    CarouselModule,
    InvestmentsComponent,
    SecurityComponent,
    PartnersComponent,
    ContactUsComponent,
    TestimonialsComponent,
    WavesComponent,
  ],
  providers: [
    ApiService,
    LoginService,
    ContactUsService,
    MenuBarService,
    SideBarService,
    SummaryService,
    DatePipe,
    UtilService,
    LoaderService,
    LoansService,
    SettingsService,
    ConfigService,
    ToolsService,
    StatementService,
    IncomeService,
    FlexiService,
    DataService,
    GroupsService,
    TransactionsService,
    ProductApplicationService,
    CommissionsService,
    KycService,
    AccumulationService,
    DocumentsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorTokenService,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorTokenService implements HttpInterceptor {
  constructor(private loginService: LoginService) {}

  // We intercept each HTTP response and if we detect an invalid token we logout the user.
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.checkInvalidToken(event.body);
        }
        return event;
      })
    );
  }

  private checkInvalidToken(body: any) {
    if (
      body.success === false &&
      body.message
        .toLowerCase()
        .replace(/\s/g, '')
        .includes('invalidblueassetapitoken')
    ) {
      this.loginService.logout();
    }

    if (
      body.success === false &&
      body.message.toLowerCase().replace(/\s/g, '').includes('missingheaders')
    ) {
      this.loginService.logout();
    }
  }
}

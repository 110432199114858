<div class="background">
  <div class="main-form-holder">
    <div class="main-header">
      <div class="get-in-touch">Get In Touch</div>
      <div class="sub-header-text">We look forward to hearing from you</div>
    </div>
    <div class="main-holder">
      <div class="form-holder">
        <form
          [formGroup]="contactForm"
          class="login-container"
          (ngSubmit)="onSubmit()"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-danger': isSubmitted && formControls.name.errors
            }"
          >
            <label class="form-control-label">Full Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              formControlName="name"
              autocomplete="off"
              class="form-control"
              [ngClass]="{
                'is-invalid': isSubmitted && formControls.name.errors
              }"
            />
            <div
              *ngIf="isSubmitted && formControls.name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formControls.name.errors.required">
                Name is required!
              </div>
            </div>
          </div>

          <div
            class="form-group mt-2"
            [ngClass]="{
              'has-danger': isSubmitted && formControls.email.errors
            }"
          >
            <label class="form-control-label">Email</label>
            <input
              type="email"
              placeholder="Enter Email"
              formControlName="email"
              autocomplete="off"
              class="form-control"
              [ngClass]="{
                'is-invalid': isSubmitted && formControls.email.errors
              }"
            />
            <div
              *ngIf="isSubmitted && formControls.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formControls.email.errors.required">
                Email is required!
              </div>
            </div>
          </div>

          <div
            class="form-group mt-2"
            [ngClass]="{
              'has-danger': isSubmitted && formControls.company.errors
            }"
          >
            <label class="form-control-label">Company</label>
            <input
              type="text"
              placeholder="Enter Company"
              formControlName="company"
              autocomplete="off"
              class="form-control"
              [ngClass]="{
                'is-invalid': isSubmitted && formControls.company.errors
              }"
            />
            <div
              *ngIf="isSubmitted && formControls.company.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formControls.company.errors.required">
                Company is required!
              </div>
            </div>
          </div>

          <div class="form-group mt-2">
            <label class="form-control-label">Telephone</label>
            <ngx-intl-tel-input
              [cssClass]="'form-control'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.SouthAfrica"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              [numberFormat]="PhoneNumberFormat.International"
              name="tel"
              formControlName="tel"
              [ngClass]="{
                'is-invalid': isSubmitted && formControls.tel.errors
              }"
            >
            </ngx-intl-tel-input>
            <div
              *ngIf="isSubmitted && formControls.tel.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formControls.tel.errors.required">
                Telephone is required!
              </div>
              <div
                *ngIf="
                  formControls.tel.invalid && formControls.tel.value !== null
                "
              >
                Telephone is invalid!
              </div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label class="form-control-label">Message</label>
            <textarea
              formControlName="message"
              class="form-control"
              cols="40"
              rows="7"
              [ngClass]="{
                'is-invalid': isSubmitted && formControls.message.errors
              }"
            ></textarea>
            <div
              *ngIf="isSubmitted && formControls.message.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formControls.message.errors.required">
                Message is required!
              </div>
            </div>
          </div>
          <div class="send-btn-h">
            <input type="submit" value="Send" class="btn contact-btn" />
          </div>
        </form>
      </div>
      <div class="contact-holder">
        <div class="gold-header">CONTACT US</div>
        <div class="inline-contact-item mt-4">
          <div>
            <div (click)="openMap()" class="contact-icon">
              <span class="fa fa-map-marker fa-2x"></span>
            </div>
          </div>
          <div>
            <div><b>Address:</b></div>
            <div>22 Karee Street, Southdowns Office Park</div>
            <div>Unit GF08, Block A</div>
            <div>Irene (Ground Floor)</div>
            <div>South Africa</div>
          </div>
        </div>
        <div class="inline-contact-item mt-4">
          <div>
            <div (click)="openPhone()" class="contact-icon">
              <span class="fa fa-phone fa-2x"></span>
            </div>
          </div>
          <div>
            <div><b>Phone:</b></div>
            <div>(+27) 12 880 1390</div>
          </div>
        </div>
        <div class="inline-contact-item mt-4">
          <div>
            <div (click)="openWeb()" class="contact-icon">
              <span class="fa fa-globe fa-2x"></span>
            </div>
          </div>
          <div>
            <div><b>Website:</b></div>
            <div>
              <a class="web-link" href="https://blueassetgroup.com"
                >blueassetgroup.com</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Send Email Modal Popup -->
<ng-template #emailModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ emailHeader }}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="hideModal()"
    ></button>
  </div>
  <div class="modal-body">
    {{ emailMessage }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary email-btn"
      (click)="hideModal()"
    >
      OK
    </button>
  </div>
</ng-template>

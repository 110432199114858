import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { MembershipComponent } from './membership/membership.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { InvestmentsComponent } from './home/investments/investments.component';
import { SecurityComponent } from './home/security/security.component';
import { PartnersComponent } from './home/partners/partners.component';
import { TestimonialsComponent } from './home/testimonials/testimonials.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';

const routes: Routes = [
  { path: 'za', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'investments', component: InvestmentsComponent },
  { path: 'security', component: SecurityComponent },
  { path: 'partners', component: PartnersComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsConditionsComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'membership/:id', component: MembershipComponent },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'za', pathMatch: 'full' },
  { path: '**', redirectTo: 'za', pathMatch: 'full' },
];

const config: ExtraOptions = {
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

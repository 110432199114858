import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  public isLoading: boolean;
  public isCollapsed: boolean;
  public mouseOvered: boolean;
  isScrolled = false;
  isBlurred = false;

  constructor() {
    this.isCollapsed = true;
    this.isLoading = true;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isScrolled = window.scrollY > 100;
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    this.mouseOvered = false;
    this.toggleBlur(); // Add this line
  }

  toggleBlur(): void {
    if (window.innerWidth <= 991.98) {
      this.isBlurred = !this.isBlurred;
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.isLoading = false;
  }
}

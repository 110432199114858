import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ContactUsService } from '../../services/contact-us.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '../../services/loader.service';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
  NgxIntlTelInputModule,
} from 'ngx-intl-tel-input-gg';
import { UtilService } from '../../services/util.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
  ],
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  @ViewChild('emailModal') emailModal: TemplateRef<any>;
  public modalRef: BsModalRef;
  contactForm: UntypedFormGroup;
  public isSubmitted: boolean;
  public showEmail: boolean;
  public emailMessage: string;
  public emailHeader: string;

  // Tel Input
  public separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.SouthAfrica,
    CountryISO.UnitedStates,
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private contactService: ContactUsService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private utilService: UtilService
  ) {
    this.emailMessage = '';
    this.emailHeader = '';
  }

  ngOnInit(): void {
    this.loaderService.updateLoader(true);

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      company: ['', Validators.required],
      tel: ['', Validators.required],
      message: ['', Validators.required],
    });

    this.emailMessage = '';
    this.emailHeader = '';
    this.showEmail = false;
    this.isSubmitted = false;
    this.loaderService.updateLoader(false);
  }

  get formControls() {
    return this.contactForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.showEmail = false;

    if (this.contactForm.invalid) {
      return;
    }

    this.loaderService.updateLoader(true);

    let payload = {
      name: this.contactForm.get('name').value,
      email: this.contactForm.get('email').value,
      tel: this.contactForm.get('tel').value?.e164Number,
      company: this.contactForm.get('company').value,
      message: this.contactForm.get('message').value,
      type: 'General',
    };
    this.contactService.sendEmail(payload).subscribe(
      (res) => {
        if (res.success) {
          this.emailMessage =
            'Thank you for your email. We will be in contact.';
          this.emailHeader = 'Thank You';
          this.showEmailModal();
        } else {
          this.emailMessage = 'Unable to send email. Please try again later!';
          this.emailHeader = 'Error';
          this.showEmailModal();
        }

        this.isSubmitted = false;
        this.contactForm.reset();

        this.loaderService.updateLoader(false);
      },
      (error) => {
        this.emailMessage = 'Unable to send email. Please try again later!';
        this.emailHeader = 'Error';
        this.showEmailModal();
        this.loaderService.updateLoader(false);
        this.contactForm.reset();
        this.isSubmitted = false;
      }
    );

    this.contactForm.reset();
  }

  openMap() {
    window.open('https://maps.app.goo.gl/vovHuZy4CcQDaUTm6', '_blank');
  }

  openWeb() {
    window.open('https://blueassetgroup.com', '_blank');
  }

  openPhone() {
    window.open('tel:+27128801390');
  }

  showEmailModal() {
    this.modalRef = this.modalService.show(this.emailModal);
    this.utilService.scrollToTop();
  }

  hideModal() {
    this.modalRef.hide();
  }
}

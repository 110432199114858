import { Component, OnInit, ElementRef } from '@angular/core';
import { LoginService } from './services/login.service';
import { Router } from '@angular/router';
import { UtilService } from './services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLoggedIn: boolean;

  constructor(
    private loginService: LoginService,
    private utilService: UtilService,
    private router: Router,
    private _elementRef: ElementRef
  ) {
    this.loginService.login.subscribe((res) => {
      this.isLoggedIn = res;
    });

    this.router.events.subscribe((val: any) => {
      this.utilService.scrollToTop();
    });
  }

  ngOnInit() {
    this._elementRef?.nativeElement?.removeAttribute('ng-version');
  }
}

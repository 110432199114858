<div class="security-holder row">
  <h2 class="security-header">NEXT-LEVEL SECURITY</h2>
  <div class="card col-md-3" data-animation="fade-up">
    <div class="card-body security-holder-item">
      <div class="icon-holder">
        <i class="fa fa-shield"></i>
      </div>
      <h5 class="card-title">Secure Custody</h5>
      <p class="card-text security-text">
        We secure your assets using the safest protocols and practices by using
        the latest blockchain technologies.
      </p>
    </div>
  </div>
  <div class="card col-md-3" data-animation="fade-up">
    <div class="card-body security-holder-item">
      <div class="icon-holder">
        <i class="fa fa-server"></i>
      </div>
      <h5 class="card-title">Layered Security</h5>
      <p class="card-text security-text">
        We use multiple layers of protection, protecting your account and
        digital assets.
      </p>
    </div>
  </div>
  <div class="card col-md-3" data-animation="fade-up">
    <div class="card-body security-holder-item">
      <div class="icon-holder">
        <i class="fa fa-users"></i>
      </div>
      <h5 class="card-title">Compliance</h5>
      <p class="card-text security-text">
        We adhere to regulatory standards to keep you and BlueAsset on the right
        side of the law.
      </p>
    </div>
  </div>
</div>

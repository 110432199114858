import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private uploadbusinessdocumentKey = 'uploadbusinessdocument';
  private getbusinessdocumentsKey = 'getbusinessdocuments';
  private changedocumentstateKey = 'changedocumentstate';
  private downloaddocumentKey = 'downloaddocument';
  private uploadclientdocumentKey = 'uploadclientdocument';
  private getdocumentsforusersKey = 'getdocumentsforusers';

  constructor(private apiService: ApiService) {}

  uploadBusinessDocument(data) {
    return this.apiService.post(this.uploadbusinessdocumentKey, data);
  }

  uploadClientDocument(data) {
    return this.apiService.post(this.uploadclientdocumentKey, data);
  }

  getBusinessDocuments(data) {
    return this.apiService.post(this.getbusinessdocumentsKey, data);
  }

  changeDocumentState(data) {
    return this.apiService.post(this.changedocumentstateKey, data);
  }

  downloadDocument(data) {
    return this.apiService.post(this.downloaddocumentKey, data);
  }

  getDocumentsForUsers(data) {
    return this.apiService.post(this.getdocumentsforusersKey, data);
  }
}

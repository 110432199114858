import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  AfterContentInit,
  AfterViewInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss'],
})
export class DiscoverComponent implements OnInit {
  @ViewChild('maincards') maincards: ElementRef;

  public unsetSticky1 = false;
  public unsetSticky2 = false;
  public unsetSticky3 = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  goToSection(el: HTMLElement) {
    el.scrollIntoView();
  }
}

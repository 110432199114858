import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  isVisible = false;

  ngOnInit() {
    this.showModalWithTimeout();
  }

  showModalWithTimeout() {
    const cookieAccepted = localStorage.getItem('BlueAssetcookiesAccepted');
    if (!cookieAccepted ) {
      setTimeout(() => {
        this.isVisible = true;
      }, 5000); // Show the modal after 5 seconds
    }
  }

  acceptCookies() {
    this.isVisible = false;
    localStorage.setItem('BlueAssetcookiesAccepted', 'true');
  }
}

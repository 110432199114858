import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class KycService {
  private getkycclienttokenKey = 'getkycclienttoken';
  private createkycchecksKey = 'createkycchecks';
  private overridekycforuserKey = 'overridekycforuser';

  constructor(private apiService: ApiService) {}

  getKycClientToken(data) {
    return this.apiService.post(this.getkycclienttokenKey, data);
  }

  createKycChekcs(data) {
    return this.apiService.post(this.createkycchecksKey, data);
  }

  overrideKycForUser(data) {
    return this.apiService.post(this.overridekycforuserKey, data);
  }
}

<div class="main-holder">
  <h1>BLUE ASSET FS AFRICA DISCLAIMER</h1>

  <p>
    <strong>Disclaimer: </strong>Cryptoasset services are not regulated by any
    regulatory body. <br />
    Please be aware that:
  </p>
  <ul>
    <li>
      (i) you will have no recourse to the Financial Ombudsman Service or the
      Financial Service Conduct Authority in the event of a complaint relating
      to Cryptoasset services;
    </li>
    <li>
      (ii) your Account is not protected by a financial services compensation
      scheme; and
    </li>
    <li>
      (iii) Cryptoassets may experience frequent price volatility resulting in a
      high degree of risk and can result in the loss of your invested capital.
      Before investing, you should ensure that you fully understand all the
      risks involved and take into consideration your level of experience and
      investment objectives.
    </li>
  </ul>
  <p>
    You should not invest more than you can afford to lose. It is your
    responsibility to ascertain whether you are permitted to use our services
    based on the legal restrictions in your country of residence. We do not sell
    any of your personal information to third parties for marketing purposes.
    The information on this site is not directed at residents of the United
    States or any particular country where such distribution or use would be
    contrary to local law or regulation. Blueasset Capital has offices in South
    Africa. Blueasset Capital Protect (Pty) Ltd is a company registered in South
    Africa with company number 2021/312400/07 and registered address at 22 Karee
    Street, Unit GF08, Block A, Southdowns Office Park, Irene (Ground Floor),
    Centurion, 0157, South Africa.
  </p>
</div>

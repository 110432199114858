import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  private emailKey = 'sendemail'

  constructor(private apiService: ApiService) { 

  }

  public sendEmail(data: any) {
    return this.apiService.post(this.emailKey, data)
  }
}

<div>
  <div class="main-landing">
    <div class="main-left">
      <h1 class="main-left-header">
        Take Advantage of Our Investment Solutions
      </h1>
      <div class="main-sub-text">
        We believe the opportunities resulting from disruptive innovation often
        are underestimated or misunderstood by traditional investment managers.
        BlueAsset identifies market inefficiencies that may cause investors to
        miss out on future growth driven by disruptive innovation.
      </div>
    </div>
    <div class="main-right">
      <img src="assets/images/world_purple.png" />
    </div>
  </div>
  <div class="sub-landing">
    <div class="solution-header">
      <h2 class="sub-header-cards">
        We have three distinct investment solutions
      </h2>
    </div>
    <div class="sub-landing-inner">
      <div class="invest-cards">
        <div class="invest-card">
          <div class="invest-card-inner">
            <div class="invest-card-header">By Asset Class</div>
            <div class="mb-4">
              <ul>
                <li>Bitcoin Fund</li>
                <li>Ethereum Fund</li>
                <li>USD Based Fund</li>
                <li>Index Fund</li>
              </ul>
            </div>
            <div (click)="goToSection(assets)" class="learn-more">
              Learn More &#8594;
            </div>
          </div>
        </div>
        <div class="invest-card">
          <div class="invest-card-inner invest-card-inner-middle">
            <div class="invest-card-header">By Investor Type</div>
            <div class="mb-4">
              <ul>
                <li>Advisor</li>
                <li>Individual</li>
                <li>Corporate</li>
                <li>Institutional</li>
              </ul>
            </div>
            <div (click)="goToSection(investors)" class="learn-more">
              Learn More &#8594;
            </div>
          </div>
        </div>
        <div class="invest-card">
          <div class="invest-card-inner">
            <div class="invest-card-header">By Product Type</div>
            <div class="mb-4">
              <ul>
                <li>Premier Flexi Plan</li>
                <li>Group Flexi Plan</li>
                <li>Accumulation Fund</li>
              </ul>
            </div>
            <div (click)="goToSection(products)" class="learn-more">
              Learn More &#8594;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #assets class="sub-holder">
    <div class="sub-left">
      <h2
        [ngClass]="{ 'unset-stick': unsetSticky1 === true }"
        class="sub-header-cards"
      >
        By Asset Class
      </h2>
    </div>
    <div class="card-slider-items">
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_btc_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Bitcoin Fund</h3>
            <p class="card-slider-item-description mt-3">
              Years ago declaring Bitcoin as a part of a new digital asset class
              was controversial, but with major traditional financial
              institutions such as Goldman Sachs admitting it is not only a new
              asset class, but an investable asset class, the tide has turned.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_eth_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Ethereum Fund</h3>
            <p class="card-slider-item-description">
              Ethereum is the perfect foundation for DeFi for a number of
              reasons: No one owns Ethereum or the smart contracts - this gives
              everyone an opportunity to use DeFi. This also means no single
              individual has control. DeFi products all based on the same
              blockchain technology: Ethereum.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_usd_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">USD Based Fund (Stablecoin)</h3>
            <p class="card-slider-item-description">
              Because of their stability, stablecoins are the ideal tools that
              can link blockchain networks with traditional economies. They help
              users streamline payments through automation while maintaining
              liquidity, security, and transparency.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/research_icon.svg"
              />
            </div>
            <h3 class="card-slider-item-title">BlueAsset Index Fund</h3>
            <p class="card-slider-item-description">
              BlueAsset Index fund was created as a combination of the more
              volatile BTC, and ETH crypto assets but with stability of the
              stable coin. This created a very unique fund that provides clients
              with the perfect balance of assets with high growth potential but
              with a majority of investment in a USD based stable coin.
              BlueAsset also incorporates the index fund within the very unique
              BlueAsset Coin(Token). This represents the profit equity of 10%
              liquidity in the overall fund structure.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div #investors class="sub-holder">
    <div class="sub-mobile">
      <h2
        [ngClass]="{ 'unset-stick': unsetSticky2 === true }"
        class="sub-header-cards"
      >
        By Investor Type
      </h2>
    </div>
    <div class="card-slider-items">
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_advisor_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Advisor</h3>
            <p class="card-slider-item-description mt-3">
              BlueAsset Group unique product development and infrastructure
              allow advisors to supply their clients with a new approach to
              their financial future and wealth creation. BlueAsset commission
              structures and approach is based on the performance fees structure
              of the fund and not taken from clients invested funds as per
              traditional commission structures.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_individual_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Individual</h3>
            <p class="card-slider-item-description">
              Every individuals financial needs are different from one another,
              therefore BlueAsset implemented product design for individuals to
              tailor their investment product to suit their needs.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_corporate_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Corporate</h3>
            <p class="card-slider-item-description">
              BlueAsset Group product was specifically designed for companies
              which place emphasis on the wellbeing of their employees. Each
              company can tailor the group product to suit their needs from
              industries needs as well as employee needs. For example if a
              company is more focused on retirement as opposed to other
              benefits. Maternity additional payments without losing retirement
              funding.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_institutional_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Institutional</h3>
            <p class="card-slider-item-description">
              82% of small businesses fail due to cash flow problems. And while
              most small business owners agree cash flow is the #1 risk for
              small businesses, cash flow is also a blanket term - a symptom, if
              you will - of several underlying causes. <br />
              <br />BlueAsset wealth formula can be implemented into a
              institutions financial plan to assist and direct monthly cash flow
              requirements but also to allow them to invest and build assets
              that increase the company's value.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-right">
      <h2
        [ngClass]="{ 'unset-stick': unsetSticky2 === true }"
        class="sub-header-cards"
      >
        By Investor Type
      </h2>
    </div>
  </div>

  <div #products class="sub-holder">
    <div class="sub-left">
      <h2
        [ngClass]="{ 'unset-stick': unsetSticky3 === true }"
        class="sub-header-cards"
      >
        By Product Type
      </h2>
    </div>
    <div class="card-slider-items">
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/icon_income_white.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Premier Flexi Plan</h3>
            <p class="card-slider-item-description mt-3">
              The BlueAsset Premier Flexi Income Plan is a digital crypto income
              plan that earns clients a Monthly Percentage Yield starting at
              2,5% on invested funds as per product guidelines, and depending on
              underling asset class fund that was selected. Clients may choose
              to invest a lump sum or ad hoc. Main features include Inflation
              cover and monthly percentage boost. Client can choose from 4
              underlining asset class funds or a combination depending on
              individual's risk appetite.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img class="card-slide-icon" src="assets/icons/money_plant.svg" />
            </div>
            <h3 class="card-slider-item-title">Group Flexi Plan</h3>
            <p class="card-slider-item-description">
              The BlueAsset Group Flexi Income Plan was designed to assist any
              group or entity to tailor the BlueAsset flexi income plan to suit
              their needs. It provides individual clients within the group the
              opportunity to participate in the global investment structure with
              lower funding requirements and monthly contribution.
            </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img
                class="card-slide-icon"
                src="assets/icons/research_icon.svg"
              />
            </div>
            <h3 class="card-slider-item-title">Accumulation Fund</h3>
            <p class="card-slider-item-description">
              Accumulation fund is the backbone of the BlueAsset fund and can be
              activated as a stand-alone product. This is better suited for
              individuals who would prefer to implement a buy, hold and
              accumulate strategy within the crypto assets to achieve future
              profits.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader = new BehaviorSubject<boolean>(false);
  isLoading = this.loader.asObservable();

  constructor() { }

  updateLoader(val) {
    this.loader.next(val)
  }
}

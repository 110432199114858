<div>
  <div class="banner-content">
    <div class="content">
      <div class="main-holder">
        <div>
          <div class="top-left">
            <h2 class="main-header">Not Your Traditional Investment Platform</h2>
          </div>
          <div class="below-quote">
            The world is changing rapidly. While traditional investors seek safety in benchmarks and traditional
            strategies, BlueAsset believes this behaviour is counterproductive. Innovation is causing disruption and the
            risks associated with the traditional investment approach are rising. We strive to invest at the pace of
            innovation.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-holder">
    <div class="sub-left">
      <h2 [ngClass]="{'unset-stick': unsetSticky === true}" class="sub-header">What sets us apart?</h2>
    </div>
    <div class="card-slider-items">
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img class="card-slide-icon" src="assets/icons/innovation_icon.svg">
            </div>
            <h3 class="card-slider-item-title"> Sole Focus on Disruptive Innovation
            </h3>
            <p class="card-slider-item-description mt-3">BlueAsset focuses solely on offering investment solutions to
              capture
              disruptive innovation in the public equity markets. </p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img class="card-slide-icon" src="assets/icons/experience_icon.svg">
            </div>
            <h3 class="card-slider-item-title"> Experienced Investment Leadership</h3>
            <p class="card-slider-item-description">BlueAsset's CEO and Head Of Technology have worked together for
              years and recognize that innovation demands a dynamic approach.</p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img class="card-slide-icon" src="assets/icons/money_plant.svg">
            </div>
            <h3 class="card-slider-item-title"> Thematic Analysts Organized by
              Innovation</h3>
            <p class="card-slider-item-description">BlueAsset's analysts are organized by cross-sector innovation theme
              to
              capitalize on technological convergence across markets and industries.</p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img class="card-slide-icon" src="assets/icons/research_icon.svg">
            </div>
            <h3 class="card-slider-item-title"> Transparent and Open Research Ecosystem
            </h3>
            <p class="card-slider-item-description">BlueAsset employs an open research approach that adds technology
              concepts
              and external inputs to traditional financial research, creating a more transparent and interdisciplinary
              investment process.</p>
          </div>
        </div>
      </div>
      <div class="card-slider-item">
        <div class="card-slider-item-inner">
          <div class="card-slider-item-content">
            <div class="card-slide-icon-h">
              <img class="card-slide-icon" src="assets/icons/graph_icon.svg">
            </div>
            <h3 class="card-slider-item-title">Long-Term Investment Time Horizon</h3>
            <p class="card-slider-item-description">The market can be distracted by short-term price movements.
              BlueAsset's
              active management focuses on the long-term effect of disruptive technologies.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ceo-section">
    <div>
      <img class="ceo-image" src="assets/images/ceo.jpeg" />
    </div>
    <div class="ceo-section-right">
      <h4 class="title-emp">Pierre Oosthuizen</h4>
      <div class="ceo-sub-text">Founder</div>
      <div class="ceo-sub-text">Chief Executive Officer</div>
      <div class="ceo-sub-text">Chief Investment Officer</div>
      <div class="ceo-sub-sub-text">
        Pierre founded BlueAsset Group for two reasons: First, to focus solely on disruptive innovation, primarily in
        the DeFi space. Second, to open up research, becoming a sharing economy company in the asset management space.
      </div>
      <div class="ceo-quote">
        “We’re all about finding the next big thing. Those hewing to the benchmarks, which are backwards looking, are
        not about the future. They are about what has worked. We’re all about what is going to work.”
      </div>
    </div>
  </div>
  <h2 class="team-h">Our Team
    <div class="thin-gold-line"></div>
  </h2>
  <div class="team-holder">
    <div class="team-item">
      <div>
        <img class="team-image" src="assets/images/team_technology.png" />
      </div>
      <div class="mt-2">
        <h5 class="title-emp">Willie Schoeman</h5>
        <div class="emp-sub-text">Chief Technologist & Co-Founder</div>
      </div>
    </div>
    <div class="team-item">
      <div>
        <img class="team-image" src="assets/images/tech_team.png" />
      </div>
      <div class="mt-2">
        <h5 class="title-emp">Christian de Beer</h5>
        <div class="emp-sub-text">Head Of Development & Co-Founder</div>
      </div>
    </div>
    <div class="team-item">
      <div>
        <img class="team-image" src="assets/images/team_sales_marketing.jpg" />
      </div>
      <div class="mt-2">
        <h5 class="title-emp">Ronel van Heerden</h5>
        <div class="emp-sub-text">Public Relations & Marketing Manager</div>
      </div>
    </div>
    <div class="team-item">
      <div>
        <img class="team-image" src="assets/images/sales_team.png" />
      </div>
      <div class="mt-2">
        <h5 class="title-emp">Paul Jones</h5>
        <div class="emp-sub-text">Head Of International Sales</div>
      </div>
    </div>
    <div class="team-item">
      <div>
        <img class="team-image" src="assets/images/team_account_2.png" />
      </div>
      <div class="mt-2">
        <h5 class="title-emp">Liz Rennie</h5>
        <div class="emp-sub-text">Account Manager</div>
      </div>
    </div>
    <div class="team-item">
      <div>
        <img class="team-image" src="assets/images/team_client_2.png" />
      </div>
      <div class="mt-2">
        <h5 class="title-emp">Kaylah Reinhardt</h5>
        <div class="emp-sub-text">Client Relations Manager</div>
      </div>
    </div>
  </div>

  <!-- Roadmap -->
  <div class="white-background center-align roadmap-section">
    <div class="container">
      <h2 class="roadmap-header-main">Roadmap</h2>
      <div class="roadmap-sub-text">
        Roadmap will continuously change as we deliver and expand on our vision. For regular updates and news.
      </div>
    </div>
  </div>
  <div>
    <div class="roadmap-h">
      <div class="roadmap-l">
        <div class="roadmap-content">
          <div class="roadmap-header">BlueAsset Capital SPC</div>
          <div>
            <ul>
              <li>BlueAsset Capital Born.</li>
              <li>Caymans Comprehensive Fund Structure Created.</li>
              <li>Development and fund structure and product layout.</li>
            </ul>
          </div>
        </div>

        <div class="roadmap-mt roadmap-content">
          <div class="roadmap-header">Expand Product Range</div>
          <div>
            <ul>
              <li>Flexi Income Plan Launched.</li>
              <li>Stable Coin Fund Launced.</li>
              <li>BlueAsset Index Fund Launched.</li>
              <li>BACC (Equity) Token Launched.</li>
            </ul>
          </div>
        </div>
        <div class="roadmap-mt roadmap-content">
          <div class="roadmap-header">End 2023</div>
          <div>
            <ul>
              <li>Group Flexi Income Plan Launched.</li>
              <li>Alternative Retiremant Plan Launched.</li>
              <li>Full Blockchain integration with smart contract execution.</li>
              <li>Decentralised Application.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="roadmap-c">
        <div class="roadmap-round-icon">Q1 '21</div>
        <div class="roadmap-h-line"></div>
        <div class="roadmap-round-icon">Q2 '21</div>
        <div class="roadmap-h-line"></div>
        <div class="roadmap-round-icon">Q1 '22</div>
        <div class="roadmap-h-line"></div>
        <div class="roadmap-round-icon">Q3 '22</div>
        <div class="roadmap-h-line"></div>
        <div class="roadmap-round-icon">Q4 '23</div>
      </div>
      <div class="roadmap-r">
        <div class="roadmap-mt roadmap-content">
          <div class="roadmap-header">BTC & ETH Launch</div>
          <div>
            <ul>
              <li>Web-site launch March 2021 include:</li>
              <li>Cashout Collateral Product Launched.</li>
              <li>Accumulation Fund Launched.</li>
              <li>Fixed Income Plan Launched.</li>
            </ul>
          </div>
        </div>
        <div class="roadmap-mt roadmap-content">
          <div class="roadmap-header">Compliance/Security</div>
          <div>
            <ul>
              <li>KYC integration.</li>
              <li>Blockchain Custodian Integration.</li>
              <li>Crypto Wallet Integration.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
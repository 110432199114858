import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FlexiService {
  private flexisummaryKey = 'getflexisummary';
  private updateflexipayoutKey = 'updateflexipayout';
  private withdrawfromfixedincomeKey = 'withdrawfromfixedincome';
  private withdrawfromflexiincomeKey = 'withdrawfromflexiincome';
  private migrateflexitogroupKey = 'migrateflexitogroup';

  constructor(private apiService: ApiService) {}

  getFlexiSummary(data) {
    return this.apiService.post(this.flexisummaryKey, data);
  }

  migrateFlexiToGroup(data) {
    return this.apiService.post(this.migrateflexitogroupKey, data);
  }

  updateFlexiPayout(data) {
    return this.apiService.post(this.updateflexipayoutKey, data);
  }

  withdrawFromFlexiIncome(data) {
    return this.apiService.post(this.withdrawfromflexiincomeKey, data);
  }

  withdrawFromFixedIncome(data) {
    return this.apiService.post(this.withdrawfromfixedincomeKey, data);
  }
}

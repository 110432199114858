import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-security',
  imports: [],
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  private addgroupKey = 'addgroup';
  private updategroupKey = 'updategroup';
  private getallgroupsKey = 'getallgroups';
  private getgroupdsbyuserKey = 'getgroupdsbyuser';
  private getusersforgroupKey = 'getusersforgroup';
  private updatememberingroupKey = 'updatememberingroup';
  private downloadusersforgroupKey = 'downloadusersforgroup';
  private withdrawflexifromgroupKey = 'withdrawflexifromgroup';
  private getstatementdataforgroupKey = 'getstatementdataforgroup';
  private closegroupflexiproductformemberKey = 'closegroupflexiproductformember';
  private getstatementdataforgroupmemberKey = 'getstatementdataforgroupmember';
  private updateuseringroupflexipayoutKey = 'updateuseringroupflexipayout';
  private withdrawflexifromgroupformyselfKey =
    'withdrawflexifromgroupformyself';

  constructor(private apiService: ApiService) {}

  addGroup(data) {
    return this.apiService.post(this.addgroupKey, data);
  }

  updateGroup(data) {
    return this.apiService.post(this.updategroupKey, data);
  }

  updateMemberInGroup(data) {
    return this.apiService.post(this.updatememberingroupKey, data);
  }

  updateUserInGroupFlexiPayout(data) {
    return this.apiService.post(this.updateuseringroupflexipayoutKey, data);
  }

  withdrawFlexiFromGroup(data) {
    return this.apiService.post(this.withdrawflexifromgroupKey, data);
  }

  withdrawFlexiFromGroupForMyself(data) {
    return this.apiService.post(this.withdrawflexifromgroupformyselfKey, data);
  }

  getAllGroups(data) {
    return this.apiService.post(this.getallgroupsKey, data);
  }

  closeGroupFlexiForMember(data) {
    return this.apiService.post(this.closegroupflexiproductformemberKey, data);
  }

  getGroupsByUser(data) {
    return this.apiService.post(this.getgroupdsbyuserKey, data);
  }

  getUsersForGroup(data) {
    return this.apiService.post(this.getusersforgroupKey, data);
  }

  downloadUsersForGroup(data) {
    return this.apiService.post(this.downloadusersforgroupKey, data);
  }

  getStatementDataForGroup(data) {
    return this.apiService.post(this.getstatementdataforgroupKey, data);
  }

  getStatementDataForGroupMember(data) {
    return this.apiService.post(this.getstatementdataforgroupmemberKey, data);
  }
}

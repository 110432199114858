import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { NgOtpInputModule } from 'ng-otp-input';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountdownComponent } from 'ngx-countdown';
import { LoaderService } from '../services/loader.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('infoUserModal') infoUserModal: TemplateRef<any>;
  @ViewChild('errUserModal') errUserModal: TemplateRef<any>;
  @ViewChild('sucUserModal') sucUserModal: TemplateRef<any>;
  @ViewChild('cd', { static: false })
  public countdown: CountdownComponent;
  public modalRef: BsModalRef;

  public userForm: UntypedFormGroup;
  public passwordResetForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public accountRecoveryForm: UntypedFormGroup;

  public errorMgs: string;
  public isLoading: boolean;
  public isSubmitted: boolean;
  public isRegisterSubmitted: boolean;
  public isShowReferralLink: boolean;
  public loginType = 0;

  // Form Management
  public isShowLoginForm: boolean;
  public isShowLoginOTPForm: boolean;
  public isShowRecoveryForm: boolean;
  public isShowForgotPasswordForm: boolean;
  public isShowRegisterForm: boolean;
  public isShowRegisterSuccessForm: boolean;
  public isShowForgotPasswordOTPForm: boolean;

  private otp: string;
  public otpConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };
  public showPasswordOTP = true;
  public showLoginOTP = true;
  public passwordStrength: any;
  private didSubmitOtp = false;

  // Modal
  public modalMessage = '';

  constructor(
    private router: Router,
    formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private ngOtpInputModule: NgOtpInputModule,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute
  ) {
    this.otp = '';
    this.isLoading = false;
    this.isShowReferralLink = false;

    this.userForm = formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.passwordResetForm = formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });

    this.accountRecoveryForm = formBuilder.group({
      username: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });

    this.registerForm = formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      id: ['', [Validators.required]],
      password: ['', [Validators.required]],
      introducer: [''],
    });

    this.showForm('Login');
  }

  ngOnInit() {
    this.otp = '';
    this.didSubmitOtp = false;
    this.modalMessage = '';

    this.activatedRoute.queryParams.subscribe((params) => {
      // Parameter is passed in route ...
      this.loaderService.updateLoader(true);

      // Check if a verify Email request
      if (params && params.token) {
        // Verify the email via the token provided ...
        this.submitVerifyEmail(params.token);

        this.loaderService.updateLoader(false);
      }

      // Check for a referral request
      if (params && params.referralLink) {
        this.isShowReferralLink = true;

        // Correct display
        this.showForm('Register');

        // Set the referral link ...
        this.registerForm.controls['introducerId'].setValue(
          params.referralLink
        );
      }
    });
    this.loaderService.updateLoader(true);
    this.errorMgs = '';
    this.loaderService.updateLoader(false);
  }

  get formControls() {
    return this.userForm.controls;
  }

  get passwordresetformControls() {
    return this.passwordResetForm.controls;
  }

  get registerformControls() {
    return this.registerForm.controls;
  }

  get recoveryformControls() {
    return this.accountRecoveryForm.controls;
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  submitLogin() {
    this.showLoginOTP = true;
    this.isSubmitted = true;
    this.didSubmitOtp = false;
    this.loaderService.updateLoader(true);

    if (this.userForm.invalid) {
      this.loaderService.updateLoader(false);
      return;
    }

    this.errorMgs = '';

    const payload = {
      username: this.userForm.get('username').value,
      password: this.userForm.get('password').value,
    };

    this.loginService.authenticate(payload).subscribe(
      (res) => {
        if (res.success) {
          this.loginType = res.response.loginType;
          sessionStorage.setItem('username', payload.username);
          this.showForm('LoginOTP');
          this.loaderService.updateLoader(false);
        } else {
          this.router.navigate(['/login']);
          this.errorMgs = res.message;
          this.userForm.reset();
          this.loaderService.updateLoader(false);
        }

        this.isSubmitted = false;
      },
      (error) => {
        this.isSubmitted = false;
        this.errorMgs =
          'We are performing some maintenance at the moment. We will be back in no time!';
        this.loaderService.updateLoader(false);
      }
    );
  }

  reSubmitLogin() {
    this.isSubmitted = true;
    this.didSubmitOtp = false;
    this.errorMgs = '';
    this.loaderService.updateLoader(true);

    if (this.userForm.invalid) {
      this.loaderService.updateLoader(false);
      return;
    }

    this.showLoginOTP = false;

    const payload = {
      username: this.userForm.get('username').value,
      password: this.userForm.get('password').value,
    };

    this.loginService.authenticate(payload).subscribe(
      (res) => {
        if (res.success) {
          this.loginType = res.response.loginType;
          sessionStorage.setItem('username', payload.username);
          this.countdown.restart();
          this.loaderService.updateLoader(false);
        } else {
          this.router.navigate(['/login']);
          this.errorMgs = res.message;
          this.userForm.reset();
          this.loaderService.updateLoader(false);
        }
        this.showLoginOTP = true;
        this.isSubmitted = false;
      },
      (error) => {
        this.showLoginOTP = true;
        this.isSubmitted = false;
        this.errorMgs = 'Unable to authenticate!';
        this.loaderService.updateLoader(false);
      }
    );
  }

  submitForgotPasswordOTP() {
    this.errorMgs = '';
    this.isSubmitted = false;

    if (this.otp.length != 6) {
      return;
    }

    this.loaderService.updateLoader(true);

    this.errorMgs = '';
    const payload = {
      username: sessionStorage.getItem('username'),
      otp: this.otp,
      type: 'password-reset',
    };

    this.loginService.authenticateOTP(payload).subscribe(
      (res) => {
        if (res.success) {
          if (this.loginType === 0) {
            this.countdown.stop();
          }

          this.showForm('Login');
          this.showSuccessUserModal(res.message);
        } else {
          this.errorMgs = res.message;
        }
        this.loaderService.updateLoader(false);
      },
      (error) => {
        this.errorMgs = 'Unable to verify OTP!';
        this.isShowLoginOTPForm = false;
        this.showForm('Login');
        this.loaderService.updateLoader(false);
      }
    );
  }

  submitLoginOTP() {
    // Check the otp 6 digits.
    if (this.otp.length != 6) {
      return;
    }

    this.loaderService.updateLoader(true);

    this.errorMgs = '';
    const payload = {
      username: sessionStorage.getItem('username'),
      otp: this.otp,
      type: 'login',
    };

    this.loginService.authenticateOTP(payload).subscribe(
      (res) => {
        if (res.success) {
          if (this.loginType === 0) {
            this.countdown.stop();
          }

          sessionStorage.setItem('x-token', res.response.token);
          this.userForm.reset();
          this.router.navigate(['/account/summary']);
          this.loginService.updateLogin(true);
        } else {
          this.errorMgs = res.message;
          this.loaderService.updateLoader(false);
        }
      },
      (error) => {
        this.isSubmitted = false;
        this.errorMgs = 'Unable to authenticate OTP!';
        this.isShowLoginOTPForm = false;
        this.showForm('Login');
        this.loaderService.updateLoader(false);
      }
    );
  }

  submitForgotPassword() {
    this.isSubmitted = true;
    this.showLoginOTP = true;
    this.didSubmitOtp = false;

    if (this.passwordResetForm.invalid) {
      return;
    }

    this.errorMgs = '';
    this.loaderService.updateLoader(true);

    const payload = {
      username: this.passwordResetForm.get('username').value,
      email: this.passwordResetForm.get('email').value,
    };

    this.loginService.resetPassword(payload).subscribe(
      (res) => {
        if (res.success === true) {
          this.loginType = res.response.loginType;
          this.showForm('ForgotPasswordOTP');
          sessionStorage.setItem('username', payload.username);
        } else {
          this.passwordResetForm.reset();
          this.errorMgs = 'Unable to reset password!';
        }

        this.isSubmitted = false;
        this.loaderService.updateLoader(false);
      },
      (error) => {
        this.passwordResetForm.reset();
        this.isSubmitted = false;
        this.errorMgs = 'Unable to reset password!';
        this.loaderService.updateLoader(false);
      }
    );

    this.isSubmitted = false;
  }

  reSubmitForgotPassword() {
    this.isSubmitted = true;
    this.didSubmitOtp = false;
    this.errorMgs = '';

    if (this.passwordResetForm.invalid) {
      return;
    }

    this.showPasswordOTP = false;
    this.loaderService.updateLoader(true);

    const payload = {
      username: this.passwordResetForm.get('username').value,
      email: this.passwordResetForm.get('email').value,
    };

    this.loginService.resetPassword(payload).subscribe(
      (res) => {
        if (res.success === true) {
          this.loginType = res.response.loginType;
          this.countdown.restart();
          sessionStorage.setItem('username', payload.username);
        } else {
          this.passwordResetForm.reset();
          this.errorMgs = 'Unable to reset password!';
        }
        this.isSubmitted = false;
        this.loaderService.updateLoader(false);
        this.showPasswordOTP = true;
      },
      (error) => {
        this.showPasswordOTP = true;
        this.isSubmitted = false;
        this.errorMgs = 'Unable to reset password!';
        this.loaderService.updateLoader(false);
      }
    );

    this.isSubmitted = false;
  }

  submitRegister() {
    this.errorMgs = '';

    if (this.registerForm.invalid) {
      this.loaderService.updateLoader(false);
      return;
    }

    if (this.registerForm.get('password').value.length < 8) {
      this.errorMgs = 'Password must be atleast 8 characters long!';
      return;
    }

    let numberChar = /\d/;
    if (!numberChar.test(this.registerForm.get('password').value)) {
      this.errorMgs = 'Password must contain atleast 1 number!';
      return;
    }

    let specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!specialChars.test(this.registerForm.get('password').value)) {
      this.errorMgs = 'Password must contain atleast 1 special character!';
      return;
    }

    this.isRegisterSubmitted = true;

    this.loaderService.updateLoader(true);

    const payload = {
      email: this.registerForm.get('email').value,
      id: this.registerForm.get('id').value,
      password: this.registerForm.get('password').value,
      name: this.registerForm.get('name').value,
      introducerId: this.registerForm.get('introducer').value,
    };

    this.loginService.registerUser(payload).subscribe(
      (res) => {
        if (res.success) {
          this.loaderService.updateLoader(false);
          this.showForm('RegisterSuccess');
        } else {
          // Show generic modal that tells user that submitted details already exist. This can be ID/passport or email.
          this.showErrorUserModal(
            'The provided details are already present on our system. Please use the reset password option if you are unable to access your account. '
          );
          this.loaderService.updateLoader(false);
        }
      },
      (error) => {
        this.isRegisterSubmitted = false;
        this.errorMgs = 'Unable to register new user!';
        this.loaderService.updateLoader(false);
      }
    );
  }

  submitRecovery() {
    this.isSubmitted = true;
    this.errorMgs = '';

    if (this.accountRecoveryForm.invalid) {
      return;
    }

    this.loaderService.updateLoader(true);
    const payload = {
      username: this.accountRecoveryForm.get('username').value,
      code: this.accountRecoveryForm.get('code').value,
    };

    this.loginService.useRecoveryCode(payload).subscribe(
      (res) => {
        this.errorMgs = '';
        if (res.success === true) {
          sessionStorage.setItem('x-token', res.response.token);
          this.accountRecoveryForm.reset();
          this.router.navigate(['/account/summary']);
          this.loginService.updateLogin(true);
        } else {
          this.accountRecoveryForm.reset();
          this.accountRecoveryForm.controls['username'].setValue(
            sessionStorage.getItem('username')
          );
          this.accountRecoveryForm.controls['username'].disable();
          this.errorMgs = res.message;
        }

        this.isSubmitted = false;
        this.loaderService.updateLoader(false);
      },
      (error) => {
        this.isSubmitted = false;
        this.accountRecoveryForm.reset();
        this.accountRecoveryForm.controls['username'].setValue(
          sessionStorage.getItem('username')
        );
        this.accountRecoveryForm.controls['username'].disable();
        this.errorMgs = 'Unable to verify recovery code!';
        this.loaderService.updateLoader(false);
      }
    );
  }

  submitVerifyEmail(token) {
    this.loaderService.updateLoader(true);

    const payload = {
      token: token,
    };

    this.loginService.submitVerifyEmail(payload).subscribe(
      (res) => {
        if (res.success) {
          // Show success modal
          this.showSuccessUserModal('Your email account has been verified.');
          this.loaderService.updateLoader(false);
        } else {
          // Show generic modal that tells user that submitted details already exist. This can be ID/passport or email.
          this.showErrorUserModal('A bad or expired token was used');
          this.loaderService.updateLoader(false);
        }
      },
      (error) => {
        this.isRegisterSubmitted = false;
        this.errorMgs = 'Unable to verify email!';
        this.loaderService.updateLoader(false);
      }
    );
  }

  // Generic error modal
  showErrorUserModal(message: string) {
    this.modalMessage = message;
    this.modalRef = this.modalService.show(this.errUserModal);
  }

  // Generic error modal
  showSuccessUserModal(message: string) {
    this.modalMessage = message;
    this.modalRef = this.modalService.show(this.sucUserModal);
  }

  hideModal() {
    this.modalRef.hide();
  }

  showRecoveryCode() {
    if (this.loginType === 0) {
      this.countdown.stop();
    }

    this.accountRecoveryForm.reset();
    this.showForm('Recovery');
    this.accountRecoveryForm.controls['username'].setValue(
      sessionStorage.getItem('username')
    );
    this.accountRecoveryForm.controls['username'].disable();
  }

  /* FORM MANAGEMENT */
  showForm(formName: string) {
    this.errorMgs = '';
    this.otp = '';
    this.isSubmitted = false;
    this.isShowLoginForm = false;
    this.isShowLoginOTPForm = false;
    this.isShowRecoveryForm = false;
    this.isShowForgotPasswordForm = false;
    this.isShowRegisterForm = false;
    this.isShowRegisterSuccessForm = false;
    this.isShowForgotPasswordOTPForm = false;

    switch (formName) {
      case 'Login':
        this.userForm.reset();
        this.isShowLoginForm = true;
        break;

      case 'LoginOTP':
        this.isShowLoginOTPForm = true;
        break;

      case 'ForgotPassword':
        this.isShowForgotPasswordForm = true;
        break;

      case 'ForgotPasswordOTP':
        this.isShowForgotPasswordOTPForm = true;
        break;

      case 'Register':
        this.isShowRegisterForm = true;
        break;

      case 'RegisterSuccess':
        this.isShowRegisterSuccessForm = true;
        break;

      case 'Recovery':
        this.isShowRecoveryForm = true;
        break;

      default:
        break;
    }

    this.utilService.scrollToTop();
  }

  showSubmitLogin() {
    this.showForm('Login');
  }

  showLoginOtp() {
    this.showForm('LoginOTP');
  }

  showPasswordReset() {
    this.passwordResetForm.reset();
    this.showForm('ForgotPassword');
  }

  /* EVENTS */
  onOtpChange(input, type: string) {
    this.otp = input;
    if (this.otp.length !== 6) {
      this.didSubmitOtp = false;
    }

    if (this.didSubmitOtp === false) {
      if (type === 'login') {
        // Auto submit OTP of filled in
        if (this.otp.length === 6) {
          this.didSubmitOtp = true;
          this.submitLoginOTP();
        }
      }

      if (type === 'password-reset') {
        // Auto submit OTP of filled in
        if (this.otp.length === 6) {
          this.didSubmitOtp = true;
          this.submitForgotPasswordOTP();
        }
      }
    }
  }

  expireOTP(event) {
    if (event.action === 'done') {
      this.userForm.reset();
      this.passwordResetForm.reset();
      this.showForm('Login');
    }
  }
}

import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from 'src/app/services/loader.service';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
})
export class MembershipComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('updateUserModal') updateUserModal: TemplateRef<any>;
  @ViewChild('genericModal') genericModal: TemplateRef<any>;
  @ViewChild('referralLinkCopiedModal')
  referralLinkCopiedModal: TemplateRef<any>;
  public modalRef: BsModalRef;
  public userForm: UntypedFormGroup;

  // Modal
  public isGenericError = false;
  public genericMessage = '';

  // Register Form
  public separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.SouthAfrica,
    CountryISO.UnitedStates,
  ];
  public isSubmitted: boolean;
  public allowUserAdd: boolean;

  private introducerData: any = {
    id: '',
  };
  private sub: any;
  public date = new Date();

  public sourceOfFunds: any;

  // Bank
  public supportedBanks: any = [];
  public selectedBank: any;
  public isBankNameValid = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private modalService: BsModalService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) {
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      id: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      cell: ['', [Validators.required]],
      street: ['', [Validators.required]],
      suburb: ['', [Validators.required]],
      city: ['', [Validators.required]],
      province: ['', [Validators.required]],
      country: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      accountHolderName: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      branchCode: ['', [Validators.required]],
      introducerId: ['', [Validators.required]],
      fundsSource: ['', [Validators.required]],
      acceptForm: ['', [Validators.required]],
      referralCode: [''],
    });
  }

  ngOnInit(): void {
    this.isSubmitted = false;
    this.isGenericError = false;
    this.genericMessage = '';
    this.allowUserAdd = false;
    this.supportedBanks = this.dataService.getValrSupportedBanks();
    this.sourceOfFunds = this.dataService.getSourceOfFunds();
    this.doPreCheck();
  }

  ngAfterViewInit() {}

  doPreCheck() {
    this.allowUserAdd = false;
    this.sub = this.route.params.subscribe((params) => {
      this.introducerData.id = params['id'];
    });

    this.loaderService.updateLoader(true);
    this.usersService
      .addUserViaIntroducerCheck({
        id: this.introducerData.id,
      })
      .subscribe(
        (res) => {
          this.loaderService.updateLoader(false);
          if (res.success === true) {
            this.introducerData = {
              ...this.introducerData,
              ...res.response,
            };
            this.allowUserAdd = true;
            this.userForm.controls['introducerId'].setValue(
              this.introducerData.id
            );
          } else {
            this.router.navigate(['/home']);
          }
        },
        (err) => {
          this.loaderService.updateLoader(false);
          this.router.navigate(['/home']);
        }
      );
  }

  addUser() {
    this.isSubmitted = true;
    this.isGenericError = false;
    if (this.userForm.invalid) {
      return;
    }

    let address = {
      street: this.userForm.get('street').value,
      suburb: this.userForm.get('suburb').value,
      city: this.userForm.get('city').value,
      province: this.userForm.get('province').value,
      country: this.userForm.get('country').value,
    };

    let accountDetails = {
      bankName: this.userForm.get('bankName').value,
      accountHolderName: this.userForm.get('accountHolderName').value,
      accountNumber: this.userForm.get('accountNumber').value,
      accountType: this.userForm.get('accountType').value,
      branchCode: this.userForm.get('branchCode').value,
    };

    const payload = {
      name: this.userForm.get('name').value,
      surname: this.userForm.get('surname').value,
      id: this.userForm.get('id').value,
      email: this.userForm.get('email').value,
      cell: this.userForm.get('cell').value?.e164Number || null,
      introducerId: this.userForm.get('introducerId').value,
      address: address,
      accountDetails: accountDetails,
      fundsSource: this.userForm.get('fundsSource').value,
      referralCode: this.userForm.get('referralCode').value || '',
    };

    this.loaderService.updateLoader(true);

    this.usersService.addUserViaIntroducer(payload).subscribe(
      (res) => {
        if (res.success === true) {
          this.isGenericError = false;
          this.genericMessage = res.message;
        } else {
          this.isGenericError = true;
          this.genericMessage = res.message;
        }

        this.loaderService.updateLoader(false);
        this.showGenericModal();
      },
      (err) => {
        this.isGenericError = true;
        this.genericMessage = 'Unable to submit application. Please try again!';
        this.loaderService.updateLoader(false);
        this.showGenericModal();
      }
    );
  }

  validateSelectedBank() {
    // Get the selected bank name from the form control
    const selectedBankName = this.userForm.get('bankName').value;
    this.supportedBanks = this.dataService.getValrSupportedBanks();
    this.selectedBank = this.supportedBanks.find(
      (bank) => bank.displayName === selectedBankName
    );
    // Set to true if a bank is found, false otherwise
    this.isBankNameValid = !!this.selectedBank;

    if (this.isBankNameValid) {
      let defaultBranchCode = this.selectedBank?.defaultBranchCode;
      if (selectedBankName !== 'International') {
        this.userForm.controls['branchCode'].setValue(defaultBranchCode);
        this.userForm.controls['branchCode'].disable();
      }

      if (defaultBranchCode === undefined || defaultBranchCode === '') {
        this.userForm.controls['branchCode'].enable();
      }
    }
  }

  get userFormControls() {
    return this.userForm.controls;
  }

  showGenericModal() {
    this.modalRef = this.modalService.show(this.genericModal);
  }

  hideModal() {
    this.modalRef.hide();

    if (this.isGenericError === false) {
      this.userForm.reset();
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy() {
    this.userForm.reset();
    this.sub.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private getUserIdKey = 'getuserbyid';
  private getUsersKey = 'getusers';
  private getUserKey = 'getuser';
  private delUserKey = 'deleteusers';
  private addUserKey = 'adduser';
  private updateUserKey = 'updateuser';
  private sendresetpasswordemailKey = 'sendresetpasswordemail';
  private sendrecoverycodeemailKey = 'sendrecoverycode';
  private getusersforroleKey = 'getusersforrole';
  private getuserlinkingKey = 'getuserlinking';
  private adduserviaintroducercheckKey = 'adduserviaintroducercheck';
  private adduserviaintroducerKey = 'adduserviaintroducer';
  private verifyuserKey = 'verifyuser';
  private searchuserbyqueryKey = 'searchuserbyquery';
  private migrateuserKey = 'migrateuser';
  private getmyprofileKey = 'getmyprofile';
  private downloadusersKey = 'downloadusers';
  private getuserportfolioKey = 'getuserportfolio';
  private movedeceasedusertouserKey = 'movedeceasedusertouser';
  private downloadsummarystatementforuserKey =
    'downloadsummarystatementforuser';

  constructor(private apiService: ApiService) {}

  getUserById(data) {
    return this.apiService.post(this.getUserIdKey, data);
  }

  getMyProfile(data) {
    return this.apiService.post(this.getmyprofileKey, data);
  }

  getUserLinking(data) {
    return this.apiService.post(this.getuserlinkingKey, data);
  }

  getUserPortfolio(data) {
    return this.apiService.post(this.getuserportfolioKey, data);
  }

  getUser(data) {
    return this.apiService.post(this.getUserKey, data);
  }

  getUsers(data) {
    return this.apiService.post(this.getUsersKey, data);
  }

  downloadUsers(data) {
    return this.apiService.post(this.downloadusersKey, data);
  }

  downloadSummaryStatementForUser(data) {
    return this.apiService.post(this.downloadsummarystatementforuserKey, data);
  }

  moveDeceasedUser(data) {
    return this.apiService.post(this.movedeceasedusertouserKey, data);
  }

  delUser(data) {
    return this.apiService.post(this.delUserKey, data);
  }

  getUsersForRole(data) {
    return this.apiService.post(this.getusersforroleKey, data);
  }

  addUser(data) {
    return this.apiService.post(this.addUserKey, data);
  }

  updateUser(data) {
    return this.apiService.post(this.updateUserKey, data);
  }

  searchUserByQuery(data) {
    return this.apiService.post(this.searchuserbyqueryKey, data);
  }

  sendPasswordResetEmail(data) {
    return this.apiService.post(this.sendresetpasswordemailKey, data);
  }

  sendRecoveryCodeEmail(data) {
    return this.apiService.post(this.sendrecoverycodeemailKey, data);
  }

  addUserViaIntroducerCheck(data) {
    return this.apiService.post(this.adduserviaintroducercheckKey, data);
  }

  addUserViaIntroducer(data) {
    return this.apiService.post(this.adduserviaintroducerKey, data);
  }

  verifyUser(data) {
    return this.apiService.post(this.verifyuserKey, data);
  }

  migrateUser(data) {
    return this.apiService.post(this.migrateuserKey, data);
  }
}

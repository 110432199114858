import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  private getTransactionsForUserKey = 'gettransactionsforuser';
  private gettransactionsforgroupKey = 'gettransactionsforgroup';

  constructor(private apiService: ApiService) {}

  getTransactionsForUser(data) {
    return this.apiService.post(this.getTransactionsForUserKey, data);
  }

  getTransactionsForGroup(data) {
    return this.apiService.post(this.gettransactionsforgroupKey, data);
  }
}

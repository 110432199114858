<app-cookies class="cookies"></app-cookies>
<div>
  <div *ngIf="hasActiveBanner === false" class="banner-content">
    <div class="hero-holder">
      <div class="content">
        <div class="intro-holder">
          <h1 class="lg-header">
            BECOME YOUR
            <br />
            PERSONAL WHALE
          </h1>
        </div>
        <!-- <app-count-down></app-count-down> -->
      </div>
    </div>
  </div>
  <app-investments id="investments"></app-investments>
  <app-security id="security"></app-security>
  <app-partners id="partners"></app-partners>
  <app-testimonials id="testimonials"></app-testimonials>
  <app-contact-us id="contact"></app-contact-us>
</div>

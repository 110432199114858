import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuBarService {

  private menuExpanded = new BehaviorSubject<boolean>(true);
  isMenuExpanded = this.menuExpanded.asObservable();

  constructor() {
  }

  updateMenuExpanded(val) {
    this.menuExpanded.next(val);
  }

}
<div class="holder">
  <h2 class="header">TESTIMONIALS</h2>
  <div class="container-fluid">
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <i>
            <div class="testimonial testimoial-holder-1">
              <p>
                Blue Asset has given me the opportunity to invest wisely, making
                better choices for my financial future, I would recommend them
                to anyone looking to invest in the crypto space over a long
                term. Their service and system works smoothly and efficiently at
                all times. Thank you to the Blue Asset team.
                <br />
                <b>- Tracy Sillard </b>
              </p>
            </div>
          </i>
        </div>
        <div class="carousel-item">
          <i>
            <div class="testimonial testimoial-holder-2">
              <p>
                I want to thank BlueAsset for their commitment and their hard
                work. They do business with integrity!
                <br />
                <b>- Johan Duvenage </b>
              </p>
            </div>
          </i>
        </div>
        <div class="carousel-item">
          <i>
            <div class="testimonial testimoial-holder-3">
              <p>
                At BlueAsset, you get regular updates of what is going on and
                it's quick and easy to get access to your money. You can see
                that your money is constantly growing. It's great to be a part
                of the BlueAsset Family!
                <br />
                <b>- Adri Simon </b>
              </p>
            </div>
          </i>
        </div>
        <div class="carousel-item">
          <i>
            <div class="testimonial testimoial-holder-4">
              <p>
                I am 100% satisfied with BlueAsset Group, not only with the
                staff but with my whole portfolio. I get good earnings from it
                and there's just nothing negative I can say about BlueAsset
                Group. THANK YOU VERY MUCH!
                <br />
                <b>- Frans vd Walt </b>
              </p>
            </div>
          </i>
        </div>
        <div class="carousel-item">
          <i>
            <div class="testimonial testimoial-holder-5">
              <p>
                <i>
                  I have been a client of BlueAsset Group for over a year now
                  and my experience has been nothing short of being thoroughly
                  impressed and amazed. One could say Pierre Ooshtuizen and his
                  team are genuinely "for the people".
                  <br />
                  <b>- Faizal Daniels </b>
                </i>
              </p>
            </div>
          </i>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>
